import * as adPresets from './adPresets';


export const INTERSTITIAL_ASSETS = [
    { width: 320, height: 410, order: 0, type: "image" },
    { width: 640, height: 820, order: 1, type: "image" },
    { width: 640, height: 960, order: 2, type: "image" },
    { width: 640, height: 1080, order: 3, type: "image" },
    { width: 640, height: 1200, order: 4, type: "image" },
    { width: 640, height: 1280, order: 5, type: "image" },
    { width: 640, height: 1370, order: 6, type: "image" },
];
export const HALF_HALF_ASSETS = [
    { width: 320, height: 410, order: 0, type: 'left', side: 'left' },
    { width: 320, height: 410, order: 1, type: 'right', side: 'right' },
    { width: 640, height: 820, order: 2, type: 'left', side: 'left' },
    { width: 640, height: 820, order: 3, type: 'right', side: 'right' },
    { width: 640, height: 960, order: 4, type: 'left', side: 'left' },
    { width: 640, height: 960, order: 5, type: 'right', side: 'right' },
    { width: 640, height: 1080, order: 6, type: 'left', side: 'left' },
    { width: 640, height: 1080, order: 7, type: 'right', side: 'right' },
    { width: 640, height: 1200, order: 8, type: 'left', side: 'left' },
    { width: 640, height: 1200, order: 9, type: 'right', side: 'right' },
    { width: 640, height: 1280, order: 10, type: 'left', side: 'left' },
    { width: 640, height: 1280, order: 11, type: 'right', side: 'right' },
    { width: 640, height: 1370, order: 12, type: 'left', side: 'left' },
    { width: 640, height: 1370, order: 13, type: 'right', side: 'right' },
];
export const INLINE_ASSETS = [
    { width: 640, height: 360, order: 0, type: "poster" },
    { width: 640, height: 360, order: 1, type: "video" },
];
export const VIDEO_INTERSTITIAL_ASSETS = [
    { width: 320, height: 410, order: 0, type: "video" },
    { width: 640, height: 820, order: 1, type: "video" },
    { width: 640, height: 960, order: 2, type: "video" },
    { width: 640, height: 1080, order: 3, type: "video" },
    { width: 640, height: 1200, order: 4, type: "video" },
    { width: 640, height: 1280, order: 5, type: "video" },
    { width: 640, height: 1370, order: 6, type: "video" },
];

export const GALLERY_ASSETS = [
    { width: 640, height: 960, type: 'Background_Image', order: 0, redirectionUrl: "" },
    { width: 662, height: 352, type: 'Banner_Image', order: 1, redirectionUrl: "" },
    { width: 223, height: 48, type: 'Logo_Image', order: 2, redirectionUrl: "" },
    { width: 384, height: 571, order: 3, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 4, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 5, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 6, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 7, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 8, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 9, type: "image", redirectionUrl: "" },
    { width: 384, height: 571, order: 10, type: "image", redirectionUrl: "" },
];

export const MOBILE_BANNER_VIDEO_ASSETS = [
    { width: 640, height: 1200, type: 'Background_Image', order: 0, redirectionUrl: "" },
    { width: 456, height: 139, type: 'Top_Image', order: 1, redirectionUrl: "" },
    { width: 512, height: 288, type: 'video', order: 2, redirectionUrl: "" },
    { width: 620, height: 183, type: "Bottom_Image", order: 3, redirectionUrl: "" },
    { width: 155, height: 49, type: "Button_Image", order: 4, redirectionUrl: "" },
];

export const FLIPPER_ASSETS = [
    { width: 320, height: 410, order: 0, type: "Front_Image" },
    { width: 640, height: 820, order: 1, type: "Front_Image" },
    { width: 640, height: 960, order: 2, type: "Front_Image" },
    { width: 640, height: 1080, order: 3, type: "Front_Image" },
    { width: 640, height: 1200, order: 4, type: "Front_Image" },
    { width: 640, height: 1280, order: 5, type: "Front_Image" },
    { width: 640, height: 1370, order: 6, type: "Front_Image" },
    { width: 320, height: 410, order: 7, type: "Background_Image" },
    { width: 640, height: 820, order: 8, type: "Background_Image" },
    { width: 640, height: 960, order: 9, type: "Background_Image" },
    { width: 640, height: 1080, order: 10, type: "Background_Image" },
    { width: 640, height: 1200, order: 11, type: "Background_Image" },
    { width: 640, height: 1280, order: 12, type: "Background_Image" },
    { width: 640, height: 1370, order: 13, type: "Background_Image" },
    { width: 320, height: 410, order: 14, type: "Back_Image" },
    { width: 640, height: 820, order: 15, type: "Back_Image" },
    { width: 640, height: 960, order: 16, type: "Back_Image" },
    { width: 640, height: 1080, order: 17, type: "Back_Image" },
    { width: 640, height: 1200, order: 18, type: "Back_Image" },
    { width: 640, height: 1280, order: 19, type: "Back_Image" },
    { width: 640, height: 1370, order: 20, type: "Back_Image" },
];

export const SCRATCH_ASSETS = [
    { width: 320, height: 410, order: 0, type: "Front_Image" },
    { width: 640, height: 820, order: 1, type: "Front_Image" },
    { width: 640, height: 960, order: 2, type: "Front_Image" },
    { width: 640, height: 1080, order: 3, type: "Front_Image" },
    { width: 640, height: 1200, order: 4, type: "Front_Image" },
    { width: 640, height: 1280, order: 5, type: "Front_Image" },
    { width: 640, height: 1370, order: 6, type: "Front_Image" },
    { width: 320, height: 410, order: 7, type: "Back_Image" },
    { width: 640, height: 820, order: 8, type: "Back_Image" },
    { width: 640, height: 960, order: 9, type: "Back_Image" },
    { width: 640, height: 1080, order: 10, type: "Back_Image" },
    { width: 640, height: 1200, order: 11, type: "Back_Image" },
    { width: 640, height: 1280, order: 12, type: "Back_Image" },
    { width: 640, height: 1370, order: 13, type: "Back_Image" },
    { width: 173, height: 172, order: 14, type: "Movable_Image" },
];

export const BOUNCE_GAME_ASSETS = [
    { width: 640, height: 960, order: 0, type: "Background_Image" },
    { width: 141, height: 139, order: 1, type: "Loading_Image" },
    { width: 112, height: 133, order: 2, type: "Score_Image" },
    { width: 125, height: 128, order: 3, type: "Logo_Image" },
    { width: 458, height: 316, order: 4, type: "poster" },
    { width: 488, height: 290, order: 5, type: "poster" },
    { width: 488, height: 290, order: 6, type: "poster" },
    { width: 488, height: 290, order: 7, type: "poster" },
    { width: 488, height: 342, order: 8, type: "poster" },
    { width: 607, height: 458, order: 9, type: "poster" },
    { width: 335, height: 98, order: 10, type: "Movable_Image" },
    { width: 141, height: 139, order: 11, type: "Movable_Image" },
];

export const VIDEO_TIMER_BANNER_ASSETS = [
    { width: 320, height: 410, type: 'Background_Image', order: 0, redirectionUrl: "" },
    { width: 640, height: 820, type: 'Background_Image', order: 1, redirectionUrl: "" },
    { width: 640, height: 960, type: 'Background_Image', order: 2, redirectionUrl: "" },
    { width: 640, height: 1080, type: 'Background_Image', order: 3, redirectionUrl: "" },
    { width: 640, height: 1200, type: 'Background_Image', order: 4, redirectionUrl: "" },
    { width: 640, height: 1280, type: 'Background_Image', order: 5, redirectionUrl: "" },
    { width: 640, height: 1370, type: 'Background_Image', order: 6, redirectionUrl: "" },
    { width: 512, height: 288, type: 'video', order: 7, redirectionUrl: "" },
];

export const DESKTOP_TIMER_BANNER_ASSETS = [
    { width: 970, height: 330, type: 'Background_Image', order: 0, redirectionUrl: "" },
    { width: 512, height: 288, type: 'video', order: 1, redirectionUrl: "" },
];

export const VIDEO_GALLERY_ASSETS = [
    { width: 1280, height: 720, order: 0, type: 'video', redirectionUrl: "" },
    { width: 274, height: 298, order: 1, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 2, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 3, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 4, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 5, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 6, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 7, type: "image", redirectionUrl: "" },
    { width: 274, height: 298, order: 8, type: "image", redirectionUrl: "" },
];

export const COLLECT_OR_EVADE_GAME_ASSETS = [
    { width: 640, height: 960, order: 0, type: 'Background_Image', redirectionUrl: "" },
    { width: 360, height: 285, order: 1, type: "Title_Image", redirectionUrl: "" },
    { width: 328, height: 28, order: 2, type: "Subtitle_Image", redirectionUrl: "" },
    { width: 272, height: 191, order: 3, type: "Movable_Image", redirectionUrl: "" },
    { width: 27, height: 45, order: 4, type: "Left_Arrow_Image", redirectionUrl: "" },
    { width: 27, height: 45, order: 5, type: "Right_Arrow_Image", redirectionUrl: "" },
    { width: 201, height: 223, order: 6, type: "Ball_Image", redirectionUrl: "", score: 0 },
    { width: 201, height: 223, order: 7, type: "Ball_Image", redirectionUrl: "", score: 0 },
    { width: 201, height: 223, order: 8, type: "Ball_Image", redirectionUrl: "", score: 0 },
    { width: 201, height: 223, order: 9, type: "Ball_Image", redirectionUrl: "", score: 0 },
    { width: 201, height: 223, order: 10, type: "Ball_Image", redirectionUrl: "", score: 0 },
    { width: 640, height: 960, order: 11, type: "Background_Image", redirectionUrl: "" },
    { width: 294, height: 221, order: 12, type: "Logo_Image", redirectionUrl: "" },
    { width: 398, height: 142, order: 13, type: "Text", redirectionUrl: "" },
    { width: 410, height: 124, order: 14, type: "Button_Image", redirectionUrl: "" },
];

export const PROGRAM_VIDEO_ASSETS = [
    { width: 320, height: 410, order: 0, type: "video" },
    { width: 640, height: 820, order: 1, type: "video" },
    { width: 640, height: 960, order: 2, type: "video" },
    { width: 640, height: 1080, order: 3, type: "video" },
    { width: 640, height: 1200, order: 4, type: "video" },
    { width: 640, height: 1280, order: 5, type: "video" },
    { width: 640, height: 1370, order: 6, type: "video" },
];

export const HALF_VIDEO_ASSETS = [
    { width: 640, height: 150, order: 0, type: 'Title_Image', redirectionUrl: "" },
    { width: 592, height: 114, order: 1, type: "Subtitle_Image", redirectionUrl: "" },
    { width: 512, height: 288, order: 2, type: "video" },
];

export const STICKY_MOBILE_ASSETS = [
    { width: 1280, height: 200, order: 0, type: 'image' },
]

export const STICKY_MOBILE_SIZE = {
    id: '1280x200',
    name: '1280x200',
    width: 1280,
    height: 200
}

export const PORTRAIT_SIZE = {
    // id: '320x410',
    // name: '320×410',
    // width: 320,
    // height: 410
    id: '640x1370',
    name: '640×1370',
    width: 640,
    height: 1370
};

export const INLINE_SIZE = {
    id: '640x360',
    name: '640×360',
    width: 640,
    height: 360
}

export const DESKTOP_TIMER_BANNER_SIZE = {
    id: '970x330',
    name: '970×330',
    width: 970,
    height: 330
}

export const DESKTOP_TIMER_BANNER_SIZES = [
    {
        id: '970x330',
        name: '970×330',
        width: 970,
        height: 330
    },
    {
        id: '970x540',
        name: '970×540',
        width: 970,
        height: 540
    },
];

export const PROGRAM_VIDE_GRID_SIZES = [
    {
        id: '3x3',
        name: '3x3',
        gridRowsNum: 3,
        gridColumnsNum: 3
    },
    {
        id: '6x6',
        name: '6x6',
        gridRowsNum: 6,
        gridColumnsNum: 6
    },
    {
        id: '9x9',
        name: '9x9',
        gridRowsNum: 9,
        gridColumnsNum: 9
    },
    {
        id: '7x4',
        name: '7x4',
        gridRowsNum: 7,
        gridColumnsNum: 4
    },
    {
        id: '7x5',
        name: '7x5',
        gridRowsNum: 7,
        gridColumnsNum: 5
    },
];

const sizes = [
    {
        name: 'custom',
        sizes: [
            {
                id: '300x600',
                name: '300×600',
                hasPresets: true,
                presetOptions: adPresets.getPresets('300x600'),
                width: 300,
                height: 600
            },
        ]
    },
    {
        name: 'Square and rectangle',
        sizes: [
            {
                id: '200x200',
                name: '200×200',
                hasPresets: false,
                presetOptions: false,
                width: 200,
                height: 200
            },
            {
                id: '240x400',
                name: '240×400',
                hasPresets: false,
                presetOptions: false,
                width: 240,
                height: 400
            },
            {
                id: '250x250',
                name: '250×250',
                hasPresets: false,
                presetOptions: false,
                width: 250,
                height: 250
            },
            {
                id: '250x360',
                name: '250×360',
                hasPresets: false,
                presetOptions: false,
                width: 250,
                height: 360
            },
            {
                id: '300x250',
                name: '300×250',
                hasPresets: false,
                presetOptions: false,
                width: 300,
                height: 250
            },
            {
                id: '336x280',
                name: '336×280',
                hasPresets: false,
                presetOptions: false,
                width: 336,
                height: 280
            },
            {
                id: '580x400',
                name: '580×400',
                hasPresets: false,
                presetOptions: false,
                width: 580,
                height: 400
            }
        ]
    },
    {
        name: 'Skyscraper',
        sizes: [
            {
                id: '120x600',
                name: '120×600',
                hasPresets: false,
                presetOptions: false,
                width: 120,
                height: 600
            },
            {
                id: '160x600',
                name: '160×600',
                hasPresets: false,
                presetOptions: false,
                width: 160,
                height: 600
            },
            {
                id: '300x600',
                name: '300×600',
                hasPresets: false,
                presetOptions: false,
                width: 300,
                height: 600
            },
            {
                id: '300x1050',
                name: '300×1050',
                hasPresets: false,
                presetOptions: false,
                width: 300,
                height: 1050
            }
        ]
    },
    {
        name: 'Leaderboard',
        sizes: [
            {
                id: '468x60',
                name: '468×60',
                hasPresets: false,
                presetOptions: false,
                width: 468,
                height: 60
            },
            {
                id: '728x90',
                name: '728×90',
                hasPresets: false,
                presetOptions: false,
                width: 728,
                height: 90
            },
            {
                id: '930x180',
                name: '930×180',
                hasPresets: false,
                presetOptions: false,
                width: 930,
                height: 180
            },
            {
                id: '970x90',
                name: '970×90',
                hasPresets: false,
                presetOptions: false,
                width: 970,
                height: 90
            },
            {
                id: '970x250',
                name: '970×250',
                hasPresets: false,
                presetOptions: false,
                width: 970,
                height: 250
            },
            {
                id: '980x120',
                name: '980×120',
                hasPresets: false,
                presetOptions: false,
                width: 980,
                height: 120
            }
        ]
    },
    {
        name: 'Mobile',
        sizes: [
            {
                id: '300x50',
                name: '300×50',
                hasPresets: false,
                presetOptions: false,
                width: 300,
                height: 50
            },
            {
                id: '320x50',
                name: '320×50',
                hasPresets: false,
                presetOptions: false,
                width: 320,
                height: 50
            },
            {
                id: '320x100',
                name: '320×100',
                hasPresets: false,
                presetOptions: false,
                width: 320,
                height: 100
            }
        ]
    },
];

export const ASSETS_TYPES = [
    { 
        id: "0", 
        name: "",
        disabled: true,
    },
    {
        id: 'image',
        name: "image",
        disabled: false,
    },
    {
        id: 'poster',
        name: "poster",
        disabled: false,
    },
    {
        id: 'video',
        name: "video",
        disabled: false,
    },
    {
        id: 'Background_Image',
        name: "Background_Image",
        disabled: false,
    },
    {
        id: 'Banner_Image',
        name: "Banner_Image",
        disabled: false,
    },
    {
        id: 'Logo_Image',
        name: "Logo_Image",
        disabled: false,
    },
    {
        id: 'Top_Image',
        name: "Top_Image",
        disabled: false,
    },
    {
        id: 'Bottom_Image',
        name: "Bottom_Image",
        disabled: false,
    },
    {
        id: 'Button_Image',
        name: "Button_Image",
        disabled: false,
    },
    {
        id: 'Button_Image',
        name: "Button_Image",
        disabled: false,
    },
    {
        id: 'Front_Image',
        name: "Front_Image",
        disabled: false,
    },
    {
        id: 'Front_Image',
        name: "Front_Image",
        disabled: false,
    },
    {
        id: 'Movable_Image',
        name: "Movable_Image",
        disabled: false,
    },
    {
        id: 'Loading_Image',
        name: "Loading_Image",
        disabled: false,
    },
    {
        id: 'Score_Image',
        name: "Score_Image",
        disabled: false,
    },
    {
        id: 'Logo_Image',
        name: "Logo_Image",
        disabled: false,
    },
    {
        id: 'Title_Image',
        name: "Title_Image",
        disabled: false,
    },
    {
        id: 'Subtitle_Image',
        name: "Subtitle_Image",
        disabled: false,
    },
    {
        id: 'Left_Arrow_Image',
        name: "Left_Arrow_Image",
        disabled: false,
    },
    {
        id: 'Right_Arrow_Image',
        name: "Right_Arrow_Image",
        disabled: false,
    },
    {
        id: 'Ball_Image',
        name: "Ball_Image",
        disabled: false,
    },
    {
        id: 'Text',
        name: "Text",
        disabled: false,
    },
]

export default sizes;
