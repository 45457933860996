import * as actionTypes from '../action';
import { generateId } from '../../utils';
import divider from '../../Components/utils/images/divider.png';
import sizes, {
    PORTRAIT_SIZE,
    HALF_HALF_ASSETS,
    INTERSTITIAL_ASSETS,
    INLINE_ASSETS,
    INLINE_SIZE,
    // DESKTOP_TIMER_BANNER_SIZE,
    VIDEO_INTERSTITIAL_ASSETS,
    GALLERY_ASSETS,
    MOBILE_BANNER_VIDEO_ASSETS,
    // MOBILE_BANNER_VIDEO_SIZE,
    FLIPPER_ASSETS,
    SCRATCH_ASSETS,
    BOUNCE_GAME_ASSETS,
    VIDEO_TIMER_BANNER_ASSETS,
    DESKTOP_TIMER_BANNER_ASSETS,
    VIDEO_GALLERY_ASSETS,
    COLLECT_OR_EVADE_GAME_ASSETS,
    PROGRAM_VIDEO_ASSETS,
    HALF_VIDEO_ASSETS,
    DESKTOP_TIMER_BANNER_SIZES,
    STICKY_MOBILE_ASSETS,
    STICKY_MOBILE_SIZE
} from '../../Components/utils/options/sizes';
import {
    CUSTOM_BANNER,
    INTERSTITIAL,
    HALF_HALF,
    INLINE,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    BOUNCE_GAME,
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    PROGRAM_VIDEO,
    HALF_VIDEO,
    UNIVERSAL,
    STICKY_MOBILE
} from '../../Components/utils/options/creativeTypes';
import { DIVIDER } from '../../constants/imageTypes';

const ONE_MB = 1000000;
const defaultBgColor = '#ffffff';
const defaultSize = sizes[0].sizes[0];
const defaultTimerEndDate = new Date();
defaultTimerEndDate.setDate(defaultTimerEndDate.getDate() + 1);
defaultTimerEndDate.setHours(0, 0, 0, 0);

const initialBreakPoint = {
    id: null,
    breakPoint: 0,
    actionType: "",
    interactionPath: [],
    rewindFrom: [],
    rewindTo: [],
    redirectionUrl: "",
    onExitLoop: 0,
    loop: false,
    mute: false,
    play: false
}

const coreFields = {
    id: null,
    type: null,
    name: null,
    backgroundColor: defaultBgColor,
    backgroundImageName: null,
    backgroundImage: null,
    backgroundVideo: null,
    showColorPicker: false,
    redirectionUrl: '',
    font: '',
    textColor: 'black',
    timerEndDate: defaultTimerEndDate,
    clientId: null,
    campaignId: null,
    mute: null,
    play: null,
    darkMode: false,
    buttonsPosition: 'Bottom',
    autoClose: true,
    autoCloseTimer: 10000,
    showTimer: false,
    scratchComplete: 15,
    compression: 28,
    textBackgroundColor: 'white',
    gameDuration: 30000,
    speedInterval: 3,
    increaseSpeedBy: 0.75,
    gameBaseSpeed: 1800,
    collectOrEvade: true,
    enableGameScore: false,
    enableGameLives: false,
    currentVideos: null,
    impressionTag: '',
    impressionClickTag: '',
    programVideo: {
        gridColumnsNum: 5,
        gridRowsNum: 7,
        breakPoints: []
    },
    videoOnly: false,
    timerPosition: 'Center',
    showVideo: true,
}

// function getBreakPointId() {
//     return generateId();
// }

function getTypeDefaults(type) {
    let fields = {};
    let images = [];
    switch (type) {
        case CUSTOM_BANNER:
            fields.bannerWidth = defaultSize.width;
            fields.bannerHeight = defaultSize.height;
            fields.assets = [];
            break;
        case INTERSTITIAL:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            INTERSTITIAL_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;
        case HALF_HALF:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            HALF_HALF_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.divider = {
                uiId: generateId(),
                url: divider,
                maxSize: ONE_MB,
                width: 25,
                height: 410
            };
            break;
        case INLINE:
            fields.bannerWidth = INLINE_SIZE.width;
            fields.bannerHeight = INLINE_SIZE.height;
            INLINE_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            break;
        case VIDEO_INTERSTITIAL:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            VIDEO_INTERSTITIAL_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            break;
        case GALLERY:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            GALLERY_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;
        case MOBILE_BANNER_VIDEO:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            MOBILE_BANNER_VIDEO_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;
        case FLIPPER:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            FLIPPER_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;
        case SCRATCH:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            SCRATCH_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.divider = {
                uiId: generateId(),
                url: divider,
                maxSize: ONE_MB,
                width: 25,
                height: 410
            };
            break;
        case BOUNCE_GAME:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            BOUNCE_GAME_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.divider = {
                uiId: generateId(),
                url: divider,
                maxSize: ONE_MB,
                width: 25,
                height: 410
            };
            break;

        case VIDEO_TIMER_BANNER:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            VIDEO_TIMER_BANNER_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;

        case DESKTOP_TIMER_BANNER:
            fields.bannerWidth = DESKTOP_TIMER_BANNER_SIZES[0].width;
            fields.bannerHeight = DESKTOP_TIMER_BANNER_SIZES[0].height;
            DESKTOP_TIMER_BANNER_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;

        case VIDEO_GALLERY:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            VIDEO_GALLERY_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;

        case COLLECT_OR_EVADE_GAME:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            COLLECT_OR_EVADE_GAME_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });

            fields.assets = images;
            break;

        case PROGRAM_VIDEO:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            PROGRAM_VIDEO_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            break;

        case HALF_VIDEO:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            HALF_VIDEO_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            break;

        case STICKY_MOBILE:
            fields.bannerWidth = STICKY_MOBILE_SIZE.width;
            fields.bannerHeight = STICKY_MOBILE_SIZE.height;
            STICKY_MOBILE_ASSETS.forEach(ii => {
                let obj = Object.assign({}, ii);
                obj.uiId = generateId();
                obj.url = '';
                obj.maxSize = ONE_MB;
                images.push(obj);
            });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            fields.swiperSpeed = 2000;
            break;
        
        case UNIVERSAL:
            fields.bannerWidth = PORTRAIT_SIZE.width;
            fields.bannerHeight = PORTRAIT_SIZE.height;
            // UNIVERSAL_ASSETS.forEach(ii => {
            //     let obj = Object.assign({}, ii);
            //     obj.uiId = generateId();
            //     obj.url = '';
            //     obj.maxSize = ONE_MB;
            //     images.push(obj);
            // });
            fields.assets = images;
            fields.mute = false;
            fields.play = false;
            break;

        default:
    }
    return fields;
}

const initialState = {
    ...coreFields
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_CREATIVE_FIELDS:
            return {
                ...state,
                name: action.name,
                type: action.creativeType,
                clientId: action.clientId,
                campaignId: action.campaignId,
                ...getTypeDefaults(action.creativeType)
            }
        case actionTypes.CLEAR_CREATIVE_FIELDS:
            return {
                ...state,
                ...coreFields
            }
        case actionTypes.SET_CREATIVE_NAME:
            return {
                ...state,
                name: action.name,
            }
        case actionTypes.SET_CREATIVE_TYPE:
            return {
                ...state,
                type: action.creativeType,
            }
        case actionTypes.SET_CREATIVE_CLIENT_ID:
            return {
                ...state,
                clientId: action.clientId,
            }
        case actionTypes.SET_CREATIVE_CAMPAIGN_ID:
            return {
                ...state,
                campaignId: action.campaignId,
            }
        case actionTypes.SET_TYPE_FIELDS:
            const _divider = action.fields?.assets?.find(a => a.type === DIVIDER);
            let typeFields = getTypeDefaults(action.fields.creativeType);
            // Checks if there is a divider from creative data and sets it accordingly.
            if (_divider) {
                typeFields.divider = _divider
                action.fields.assets = action.fields.assets.filter(field => field.type !== DIVIDER)
            }

            return {
                ...state,
                ...typeFields,
                ...action.fields,
                type: action.fields.creativeType
            }
        case actionTypes.UPDATE_BANNER_NAME:
            return {
                ...state,
                name: action.name
            }
        case actionTypes.UPDATE_BANNER_SIZE:
            return {
                ...state,
                bannerWidth: action.width,
                bannerHeight: action.height
            }
        case actionTypes.ADD_ASSET:
            const maxOrder = state.assets.length - 1;

            const newAsset = {
                uiId: generateId(),
                width: action.width,
                height: action.height,
                type: action.assetType,
                order: maxOrder + 1,
                url: "",
            };

            return {
                ...state,
                assets: [
                    ...state.assets,
                    newAsset
                ]
            };
        case actionTypes.DELETE_ASSET:
            const assetsAfterDelete = state.assets.filter(asset => asset.uiId !== action.uiId);

            const updatedAssetsAfterDelete = assetsAfterDelete.map((asset, index) => ({
                ...asset,
                order: index,
            }));

            return {
                ...state,
                assets: [
                    ...updatedAssetsAfterDelete
                ]
            };
        case actionTypes.UPDATE_ASSET:
            const assetsAfterUpdate = state.assets.map(asset => 
                asset.uiId === action.uiId 
                ? { ...asset, width: action.width, height: action.height, url: '' } 
                : asset
            );

            return {
                ...state,
                assets: assetsAfterUpdate
            };
        case actionTypes.UPDATE_ASSET_SIZE:
                const newAssets = state.assets ? state.assets.map(asset => {
                    if(asset.order === action.order) {
                        return {
                            ...asset,
                            width: action.width,
                            height: action.height,
                        }
                    } else {
                        return asset;
                    }
                }) : [];
            if(newAssets && newAssets.length > 0) {
                return {
                    ...state,
                    assets: newAssets
                }
            } else {
                return state;
            }
        case actionTypes.UPDATE_BANNER_BACKGROUND_COLOR:
            let bgColor = !action.value ? defaultBgColor : action.value;
            return {
                ...state,
                backgroundColor: bgColor
            }
        case actionTypes.UPDATE_BANNER_BACKGROUND_IMAGE:
            return {
                ...state,
                backgroundImage: action.value,
                backgroundImageName: action.name
            }
        case actionTypes.UPDATE_BANNER_BACKGROUND_VIDEO:
            return {
                ...state,
                backgroundVideo: action.value
            }
        case actionTypes.UPDATE_BANNER_REDIRECTION_URL:
            return {
                ...state,
                redirectionUrl: action.value
            }
        case actionTypes.UPDATE_BANNER_FONT:
            return {
                ...state,
                font: action.value
            }
        case actionTypes.UPDATE_BANNER_TEXT_COLOR:
            return {
                ...state,
                textColor: action.value
            }
        case actionTypes.UPDATE_BANNER_TIMER_END_DATE:
            return {
                ...state,
                timerEndDate: !action.value || action.value === '' ? defaultTimerEndDate : action.value
            }
        case actionTypes.SET_SHOW_BANNER_COLOR_PICKER:
            return {
                ...state,
                showColorPicker: action.value
            }
        case actionTypes.SET_MUTE_BTN:
            return {
                ...state,
                mute: action.value
            }
        case actionTypes.SET_PLAY_BTN:
            return {
                ...state,
                play: action.value
            }
        case actionTypes.SET_BLACK_BTN:
            return {
                ...state,
                darkMode: action.value
            }
        case actionTypes.SET_BTNS_POSITION:
            return {
                ...state,
                buttonsPosition: action.value
            }
        case actionTypes.SET_AUTO_CLOSE:
            return {
                ...state,
                autoClose: action.value
            }
        case actionTypes.SET_AUTO_CLOSE_TIMER:
            return {
                ...state,
                autoCloseTimer: action.value
            }
        case actionTypes.SET_SHOW_TIMER:
            return {
                ...state,
                showTimer: action.value
            }
        case actionTypes.SET_SHOW_VIDEO:
            return {
                ...state,
                showVideo: action.value
            }
        case actionTypes.SET_COMPRESSION:
            return {
                ...state,
                compression: action.value
            }
        case actionTypes.SET_SCRATCH_COMPLETE:
            return {
                ...state,
                scratchComplete: action.value
            }
        case actionTypes.SET_SWIPER_SPEED:
            return {
                ...state,
                swiperSpeed: action.value
            }
        case actionTypes.ADD_MOBILE_STICKY_ASSET:
            const newStickyMobileAsset = {
                width: STICKY_MOBILE_SIZE.width,
                height: STICKY_MOBILE_SIZE.height,
                order: state.assets.length > 0 ? state.assets.at(-1).order + 1 : 0,
                type: 'image',
                url: '',
                uiId: generateId() 
            }
            return {
                ...state,
                assets: [...state.assets, newStickyMobileAsset]
            }
        
        case actionTypes.SET_TEXT_BACKGROUND_COLOR:
            return {
                ...state,
                textBackgroundColor: action.value
            }
        case actionTypes.SET_GAME_DURATION:
            return {
                ...state,
                gameDuration: action.value
            }
        case actionTypes.SET_SPEED_INTERVAL:
            return {
                ...state,
                speedInterval: action.value
            }
        case actionTypes.SET_INCREASE_SPEED_BY:
            return {
                ...state,
                increaseSpeedBy: action.value
            }
        case actionTypes.SET_GAME_BASE_SPEED:
            return {
                ...state,
                gameBaseSpeed: action.value
            }
        case actionTypes.SET_COLLECT_OR_EVADE:
            return {
                ...state,
                collectOrEvade: action.value
            }
        case actionTypes.SET_ENABLE_GAME_SCORE:
            return {
                ...state,
                enableGameScore: action.value
            }
        case actionTypes.SET_ENABLE_GAME_LIVES:
            return {
                ...state,
                enableGameLives: action.value
            }
        case actionTypes.SET_ASSET_SCORE: {
            const updatedAssets = [...state.assets].map(asset => {
                const isSameAsset = asset.uiId === action.uiId || asset.id === action.uiId;
                return isSameAsset ? { ...asset, score: action.value.score } : asset;
            });
            return {
                ...state,
                assets: updatedAssets
            };
        }
        case actionTypes.SET_IMPRESSION_TAG:
            return {
                ...state,
                impressionTag: action.value
            }
        case actionTypes.SET_IMPRESSION_CLICK_TAG:
            return {
                ...state,
                impressionClickTag: action.value
            }
        case actionTypes.SET_INTERSTITIAL_IMAGE:
            const updatedAssets = [...state.assets].map(asset => {
                const isSameImg = asset.uiId === action.uiId || asset.id === action.uiId;
                if (isSameImg) return { ...asset, url: action.value.image }

                return asset;
            });
            return {
                ...state,
                assets: updatedAssets
            }
        case actionTypes.SET_INLINE_VIDEO: {
            const updatedAssets = [...state.assets].map(asset => {
                const isSameAsset = asset.uiId === action.uiId || asset.id === action.uiId;
                return isSameAsset ? { 
                    ...asset, url: action.value, 
                    width: action.data.width, 
                    height: action.data.height, 
                    duration: action.data.duration, 
                    size: action.data.size 
                } : asset;
            });
            return {
                ...state,
                assets: updatedAssets
            };
        }

        case actionTypes.SET_VIDEO_INTERSTITIAL: {
            const updatedAssets = [...state.assets].map(asset => {
                const isSameAsset = asset.uiId === action.uiId || asset.id === action.uiId;
                return isSameAsset ? { ...asset, url: action.value } : asset;
            })
            return {
                ...state,
                assets: updatedAssets
            }
        }

        case actionTypes.SET_CURRENT_VIDEOS: {
            return {
                ...state,
                currentVideos: action.value
            }
        }

        case actionTypes.ADD_NEW_BREAK_POINT: {
            const initialBreakPointWithId = {
                ...initialBreakPoint, id: generateId()
            };

            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: [...state.programVideo.breakPoints, initialBreakPointWithId]
                }
            }
        }

        case actionTypes.SET_GRID_COLUMNS_NUMBER: {
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    gridColumnsNum: action.value
                }
            }
        }

        case actionTypes.SET_GRID_ROWS_NUMBER: {
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    gridRowsNum: action.value
                }
            }
        }

        case actionTypes.SET_BREAK_POINT: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, breakPoint: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.DELETE_BREAK_POINT: {
            const updatedBreakPoints = state.programVideo.breakPoints.filter(break_point => break_point.id !== action.value.id);

            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__ACTION_TYPE: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, actionType: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__INTERACTION_PATH: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, interactionPath: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__REWIND_FROM: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, rewindFrom: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT__REWIND_TO: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, rewindTo: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_REDIRECTION_URL: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, redirectionUrl: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_ON_EXIT_LOOP: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, onExitLoop: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_LOOP: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, loop: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_MUTE: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, mute: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_BREAKE_POINT_PLAY: {
            const updatedBreakPoints = [...state.programVideo.breakPoints].map(break_point => {
                const isSameBreakPoint = break_point.uiId === action.uiId || break_point.id === action.uiId;
                return isSameBreakPoint ? { ...break_point, play: action.value } : break_point;
            })
            return {
                ...state,
                programVideo: {
                    ...state.programVideo,
                    breakPoints: updatedBreakPoints
                }
            }
        }

        case actionTypes.SET_VIDEO_ONLY: {
            return {
                ...state,
                videoOnly: action.value
            }
        }

        case actionTypes.SET_TIMER_POSITION: {
            return {
                ...state,
                timerPosition: action.value
            }
        }

        case actionTypes.SET_BACKGROUND_COLOR: {
            return {
                ...state,
                backgroundColor: action.value
            }
        }

        default:
            return state;
    }

}

export default reducer