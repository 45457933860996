import './style.scss';
import React from 'react';
import Button from '../Input/Button';
import { useSelector } from 'react-redux';

import placeholder from "../../Components/utils/images/avatar_box_placeholder.png";

export default function ActionFooter({ proceedCallback }) {

    const creative = useSelector(state => state.creative);

    // v1
    // const disableButton = creative.name === "" || creative.assets?.some(asset => {
    //     if(asset.type === "video") {
    //         return asset.url === "" ? true : false;
    //     } else {
    //         return asset.url === placeholder;
    //     }
    // });

    // v2 test
    const disableButton = () => {
        // this function must return a bool
        // true = disable the button 

        // name is required!
        if(creative.name === "") return true;
        
        // inline has different check! one of the assets can be missing!
        const isInline = creative.type === "inline";

        // if not "inline", we do the same check as the v1 function 
        if (!isInline)  
            return creative.assets?.some(asset => {
                if(asset.type === "video") return asset.url === "";
                else return asset.url === placeholder;
            })
        
        // 4 cases tt/tf/ft/ff
        const videoOnly = creative.videoOnly;
        const showVideo = creative.showVideo;
        let userSetPoster = false;
        let userSetVideo = false;
        if(creative.assets?.find(i => i.type === 'video')) {
            userSetVideo = creative.assets?.find(i => i.type === 'video').url !== "";
        }
        if(creative.assets?.find(i => i.type === 'poster')) {
            userSetPoster = creative.assets?.find(i => i.type === 'poster').url !== placeholder;
        }
        
        if (videoOnly && showVideo) { // only video
            return !userSetVideo; 
        } else if (!videoOnly && showVideo) { // video with poster
           return !userSetPoster && !userSetVideo; 
        } else if (videoOnly && !showVideo) { // impossible situation
            return true;  
        } else { // !videoOnly && !showVideo
            return !userSetPoster;  
        }

    }

    // will rerender every time the creative changes
    const disabled = disableButton(); 

    return (
        <footer className="page-footer">
            <Button className="proceed-btn full-width-btn" onClick={proceedCallback} variant="contained" disabled={disabled}>Save</Button>
        </footer>
    )
}