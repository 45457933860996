import React from 'react';

import gallery from '../utils/images/creative_images/gallery.gif';
import half_half from '../utils/images/creative_images/half_half.gif';
import inline from '../utils/images/creative_images/inline.gif';
import static_interstitial from '../utils/images/creative_images/static_interstitial.png';
import video_interstitial from '../utils/images/creative_images/video_interstitial.gif';
import mobile_banner_video from '../utils/images/creative_images/mobile_banner_video.gif';
import flipper from '../utils/images/creative_images/flipper.gif';
import scratch from '../utils/images/creative_images/scratch.gif';
import bounce_game from '../utils/images/creative_images/bounce_game.png';
import video_timer_banner from '../utils/images/creative_images/video_timer_banner.gif';
import video_gallery from '../utils/images/creative_images/video_gallery.gif';
import collect_or_evade_game from '../utils/images/creative_images/collect_or_evade_game.gif';
import program_video from '../utils/images/creative_images/program_video.gif';
import half_video from '../utils/images/creative_images/half_video.gif';
import desktop_timer_banner from '../utils/images/creative_images/desktop_timer_banner.gif';
import universal from '../utils/images/creative_images/universal.gif';
import custom_banner from '../utils/images/creative_images/custom_banner.gif';

import phoneBorderImage from "../utils/images/creative_images/mobile_frame.png";
import desktopBorderImage from "../utils/images/creative_images/Desktop.png";

import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '2.5vw 0',
        gap: '2vw',
        display: 'flex',
        justifyContent: 'normal',
        alignItems: 'center',
        flexDirection: 'column',
    },
    title: { 
        width: '90%', 
        textAlign: 'left', 
        fontWeight: 'bold', 
        fontSize: '1.5vw', 
        fontFamily: 'Lexend' 
    },
    gallery_container: {
        backgroundColor: 'white',
        width: 'calc(90% - 2vw)',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '2vw',
        padding: '3vw 1vw 3vw 4vw',
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRadius: '15px'
    },
    carousel_btn_container: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        gridGap: '1vw',
        gap: '1vw',
        width: '13vw',
    },
    carousel_btn_image_container: {
        backgroundImage: `url(${phoneBorderImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        // width: 'calc(96px * 1.5)',
        // height: 'calc(192px * 1.5)',
        width: '5vw',
        height: '10vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    carousel_btn_image_container_desktop: {
        backgroundImage: `url(${desktopBorderImage})`,
        width: 'calc(10.208vw * 1.2)',
        height: 'calc(8.333vw * 1.2)',
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: '3%',
    },
    carousel_btn_image_container_full_screen_desktop: {
        paddingTop: '5%',
    },
    carousel_btn_image: {
        objectFit: 'contain',
        width: '85%',
        pointerEvents: 'none',
    },
    carousel_text: {
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1vw',
        fontFamily: 'Lexend',
    },
}));

const CreativeGallery = (props) => {
    const classes = useStyles();

    const creatives_that_dont_have_template = ['custom_banner'];
    const horizontal_creatives = ['desktop_timer_banner', 'video_gallery'];

    const creativeImages = {
        custom_banner: custom_banner,
        interstitial: static_interstitial,
        half_and_half: half_half,
        inline: inline,
        video_interstitial: video_interstitial,
        gallery: gallery,
        mobile_banner_video: mobile_banner_video,
        flipper: flipper,
        scratch: scratch,
        bounce_game: bounce_game,
        video_timer_banner: video_timer_banner,
        desktop_timer_banner: desktop_timer_banner,
        video_gallery: video_gallery,
        collect_or_evade_game: collect_or_evade_game,
        program_video: program_video,
        half_video: half_video,
        universal: universal,
        sticky_image: '../utils/images/logo4.png',
    };

    const creativeNames = {
        custom_banner: 'Custom Banner',
        interstitial: 'Static Interstitial',
        half_and_half: 'Half and Half',
        inline: 'Inline',
        video_interstitial: 'Video Interstitial',
        gallery: 'Gallery',
        mobile_banner_video: 'Mobile Banner Video',
        flipper: 'Flipper',
        scratch: 'Scratch',
        bounce_game: 'Bounce Game',
        video_timer_banner: 'Video Timer Banner',
        desktop_timer_banner: 'Desktop Timer Banner',
        video_gallery: 'Video Gallery',
        collect_or_evade_game: 'Collect or Evade Game',
        program_video: 'Program Video',
        half_video: 'Half Video',
        universal: 'Universal',
        sticky_mobile: 'Sticky Mobile',
    };

    const horizontal_creative_types = props.creativeTypes.filter(type => horizontal_creatives.includes(type));
    const vertical_creative_types = props.creativeTypes.filter(type => !horizontal_creatives.includes(type) || creatives_that_dont_have_template.includes(type));
    
    return (
        <div className={`page-container ${classes.container}`}>
            <Typography className={classes.title}>
                Creative Gallery
            </Typography>
            <Box className={classes.gallery_container}>
                {vertical_creative_types.map((type, index) => {
                    let creativeTemplateType = type;

                    return (
                        <div 
                            key={`vertical${index}`} 
                            className={classes.carousel_btn_container} 
                            onClick={() => (props.functions.length ? props.functions[index] : props.functions)(type)}
                        >
                            <div className={classes.carousel_btn_image_container}>
                                <img 
                                    className={classes.carousel_btn_image} 
                                    src={creativeImages[creativeTemplateType]} 
                                    alt=""
                                />
                            </div>
                            <p className={classes.carousel_text}>{creativeNames[type]}</p>
                        </div>
                    );
                })}
            </Box>
            <Box className={classes.gallery_container}>
                {horizontal_creative_types.map((type, index) => {
                    let creativeTemplateType = type;

                    const parent_container_styles = {
                        width: '23vw',
                    };

                    const container_styles = {
                        // transform: 'rotate(-90deg)',
                    };

                    const image_styles = {
                        width: type === 'video_gallery' ? '75%' : '85%'
                    };

                    return (
                        <div 
                            key={`horizontal${index}`} 
                            className={classes.carousel_btn_container} 
                            onClick={() => (props.functions.length ? props.functions[index] : props.functions)(type)}
                            style={parent_container_styles}
                        >
                            <div className={`${classes.carousel_btn_image_container} ${classes.carousel_btn_image_container_desktop} ${type === 'video_gallery' ? classes.carousel_btn_image_container_full_screen_desktop : ''}`} style={container_styles}>
                                <img 
                                    className={classes.carousel_btn_image} 
                                    src={creativeImages[creativeTemplateType]} 
                                    style={image_styles}
                                    alt=""
                                />
                            </div>
                            <p className={classes.carousel_text}>{creativeNames[type]}</p>
                        </div>
                    );
                })}
            </Box>
        </div>
    );
};

export default CreativeGallery;