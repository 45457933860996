import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import * as actionTypes from '../../store/action';
import { tokenKey } from '../../api_requests';
import { CAMPAIGNS, CREATIVE, CREATIVE_MANAGEMENT, LOGIN, MAIN, USERS_MANAGEMENT } from '../../constants/path';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from "@mui/styles";

import AddIcon from "../utils/images/drawer_icons/add.png";
import AppIcon from "../utils/images/drawer_icons/app.png";
import BullhornIcon from "../utils/images/drawer_icons/bullhorn.png";
import ChartIcon from "../utils/images/drawer_icons/chart.png";
import LogoutIcon from "../utils/images/drawer_icons/logout.png";
import PictureIcon from "../utils/images/drawer_icons/picture.png";
import SettingsIcon from "../utils/images/drawer_icons/settings.png";
import UserIcon from "../utils/images/drawer_icons/user.png";
import UsersIcon from "../utils/images/drawer_icons/users.png";

const useStyles = makeStyles((theme) => ({
  left_drawer: {
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      position: 'initial',
      paddingTop: 15,
      backgroundColor: '#2E3C8D',
      boxSizing: 'border-box',
      border:'none',
      width: '15vw',
      whiteSpace: 'nowrap',
      transition: '0.5s width',
      overflowX: 'hidden',
    },
  },
  close_left_drawer: {
    width: '4.167vw !important',
  },
  list_container: {
  },
  list_item: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#35A7E0',
    },
  },
  list_item_disabled: {
    opacity: '1 !important',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#2E3C8D',
    },
  },
  list_item_selected: {
    backgroundColor: '#35A7E0',
  },
  list_item_btn: {
    paddingLeft: '1.667vw',
  },
  list_item_text: {
    color: 'white',
    '& span': {
      fontSize: '1vw'
    }
  },
  list_item_icon: {
    minWidth: '2.917vw'
  },
  img: {
    objectFit: 'contain',
    width: '1vw'
  }
}));

const LeftDrawer = () => {
  const disaptch = useDispatch();
  const close = useSelector(state => state.ctrl.leftDrawerState.close);
  const currentTab = useSelector(state => state.ctrl.leftDrawerState.currentTab);
  const email = useSelector(state => state.user.email);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const classes = useStyles();

  const closeDrawerHandler = (event) => {
    disaptch({ type: actionTypes.SET_LEFT_DRAWER_CLOSE, value: true });
  };

  const openDrawerHandler = (event) => {
    disaptch({ type: actionTypes.SET_LEFT_DRAWER_CLOSE, value: false });
  };

  const handleDrawerListItemClick = (event, tabIndex) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if(currentTab === tabIndex) {
      if(close) {
        openDrawerHandler();
      } else {
        closeDrawerHandler();
      }
    }
  };

  const handleLinkClick = (linkName, index) => {
    dispatch({ type: actionTypes.SET_LEFT_DRAWER_CURRENT_TAB , value: index });
    switch(linkName) {
      case 'Dashboard': 
        navigate(MAIN);
        dispatch({ type: actionTypes.SET_GRID_PAGE_CONTENT, value: 'carousel' });
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
        case 'Creative Page': 
        navigate(CREATIVE);
        break;
        case 'Campaigns': 
        navigate(CAMPAIGNS);
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
      case 'Clients':  
        navigate(CREATIVE_MANAGEMENT);
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
      case 'Creative Format': 
        navigate(MAIN);
        dispatch({ type: actionTypes.SET_GRID_PAGE_CONTENT, value: 'gallery' });
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
      case 'Reports': 
        // dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        // dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
      case 'Settings': 
        // dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });  
        // dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });    
        break;
      case 'Users': 
        navigate(USERS_MANAGEMENT);
        dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
        dispatch({ type: actionTypes.CLEAR_NEW_CREATIVE_FIELDS });
        break;
      case 'Logout': 
        localStorage.removeItem(tokenKey);
        dispatch({ type: actionTypes.SET_LEFT_DRAWER_CURRENT_TAB , value: 0 }); // set current tab to be the first (dashboard)
        disaptch({ type: actionTypes.SET_LEFT_DRAWER_CLOSE, value: true }); // close drawer
        dispatch({ type: actionTypes.CLEAR_USER }); // clear any data about the user in local storage
        navigate(LOGIN);
        break;
      default:
        navigate('*');
    }

      // reset creative state
      dispatch({ type: actionTypes.SET_SHAPE_MENU_STATE, value: false });
      dispatch({ type: actionTypes.SET_IMAGE_MENU_STATE, value: false });
      dispatch({ type: actionTypes.SET_TEXT_MENU_STATE, value: false });
      dispatch({ type: actionTypes.CLEAR_CREATIVE_FIELDS });
  };

  const listItemObj = [
    {name: 'Dashboard', icon: AppIcon, disabled: false, },
    {name: 'Creative Page', icon: AddIcon, disabled: true, },
    {name: 'Campaigns', icon: BullhornIcon, disabled: false, },
    {name: 'Clients', icon: UsersIcon, disabled: false, },
    {name: 'Creative Format', icon: PictureIcon, disabled: false, },
    {name: 'Reports', icon: ChartIcon, disabled: true,}, 
    {name: 'Settings', icon: SettingsIcon , disabled: true,}, 
    {name: 'Users', icon: UserIcon, disabled: false,}, 
    {name: 'Logout', icon: LogoutIcon, disabled: false, },
  ];

  const isMainAccount = email === "dsdmin@dolphinsoft.co.il";
  const isUsersTab = (n) => n === "Users";


  const list = (
    <Box
      className={classes.list_container}
      role="presentation"
    >
      <List>
        {listItemObj.map((item, index) => {
          if(isUsersTab(item.name) && !isMainAccount) return;
          return (
            <ListItem className={`${classes.list_item} ${currentTab === index ? classes.list_item_selected: ""} ${item.disabled ? classes.list_item_disabled : ''}`} key={item.name} disablePadding onClick={(event) => handleDrawerListItemClick(event, index)} disabled={item.disabled}>
              <ListItemButton className={classes.list_item_btn} onClick={(event) => handleLinkClick(item.name, index)} disabled={item.disabled}>
                <ListItemIcon className={classes.list_item_icon}>
                  <img className={classes.img} src={item.icon} alt='' draggable="false" />
                </ListItemIcon>
                <ListItemText className={classes.list_item_text} primary={item.name} />
              </ListItemButton>
            </ListItem>
          )}
        )}
      </List>
    </Box>
  );

  return (
    <Drawer
      className={classes.left_drawer}
      hideBackdrop
      variant="permanent"
      PaperProps={{
        className: close ? classes.close_left_drawer : ""
      }}
    >
      {list}
    </Drawer>
  );
};

export default LeftDrawer;