export const STATIC_URL = 'https://adsil1.com/ads/static/';
export const HALF_HALF_URL = 'https://adsil1.com/ads/fifty/';
export const INLINE_URL = 'https://adsil1.com/ads/inline/';
export const VIDEO_INTERSTITIAL_URL = 'https://adsil1.com/ads/video_interstitial/';
export const GALLERY_URL = 'https://adsil1.com/ads/gallery/';
export const MOBILE_BANNER_VIDEO_URL = 'https://adsil1.com/ads/mobile_banner_video/';
export const FLIPPER_URL = 'https://adsil1.com/ads/flipper/';
export const SCRATCH_URL = 'https://adsil1.com/ads/scratch/';
export const BOUNCE_GAME_URL = 'https://adsil1.com/ads/bounce_game/';
export const VIDEO_TIMER_BANNER_URL = 'https://adsil1.com/ads/video_timer_banner/';
export const DESKTOP_TIMER_BANNER_URL = 'https://adsil1.com/ads/desktop_timer_banner/';
export const VIDEO_GALLERY_URL = 'https://adsil1.com/ads/video_gallery/';
export const COLLECT_OR_EVADE_GAME_URL = 'https://adsil1.com/ads/collect_or_evade_game/';
export const PROGRAM_VIDEO_URL = 'https://adsil1.com/ads/program_video/';
export const HALF_VIDEO_URL = 'https://adsil1.com/ads/half_video/';
export const UNIVERSAL_URL = 'https://adsil1.com/ads/universal/';
export const STICKY_MOBILE_URL = 'https://adsil1.com/ads/sticky_mobile/';