import './style.scss';
import { useState } from 'react';
import InputBox from './InputBox';
import AvatarBox from './AvatarBox';
import { Box, Divider, IconButton, Stack } from '@mui/material';
import { GrDocumentZip } from 'react-icons/gr';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DESKTOP_TIMER_BANNER, STICKY_MOBILE, VIDEO_TIMER_BANNER } from "../utils/options/creativeTypes";
import CreativeMenu from '../CreativeTemplates/CreativeMenu';
import NumberInput from '../Input/mui_inputs/NumberInput';
import { FaPlus } from 'react-icons/fa';
import { /*handleDeleteAsset,*/ handleAddAsset } from '../CreativeTemplates/handlers';
import DropDown from '../Input/DropDown';
import { ASSETS_TYPES } from '../utils/options/sizes';
import { UNIVERSAL } from '../../constants/creativeTypes';

export default function CreativeAssetsMenu(props) {
    const [width, setWidth] = useState("0");
    const [height, setHeight] = useState("0");
    const [type, setType] = useState('');

    const dispatch = useDispatch();

    const creativeType = useSelector(state => state.creative.type);
    const assets = useSelector(state => state.creative.assets);

    const creativesWithVariations = [VIDEO_TIMER_BANNER, DESKTOP_TIMER_BANNER];

    const creativeWithVariations = creativesWithVariations.includes(creativeType);

    const handleDownloadZipFile = async (event) => {
        //? download_example-0 => 0 -||- download_example-1 => 1
        const creativeVariationNumber = event.target.id.split('-')[1];
        const creativeVariationPath = creativeWithVariations ? `/${creativeVariationNumber}` : '';
        const url = `https://adsil1.com/creative_examples/${creativeType}${creativeVariationPath}/assets.zip`;
        // console.log('Downloading from:', url);

        try {
            const response = await fetch(url, { mode: 'cors' });
            const blob = await response.blob();

            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${creativeType}${creativeWithVariations ? '_' + creativeVariationNumber : ''} assets.zip`;
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            toast.error("Something went wrong! Could not download the file");
        }
    };

    const addAssetHandler = () => {
        if(!width || width === '' || width <= 0) return;
        if(!height || height === '' || height <= 0) return;
        if(!type || type === '') return;
        handleAddAsset(dispatch, width, height, type);

        // console.log('Add Assets');
    };

    return (
        <div className='creative-menu-container'>
            <div className="side-menu">
                <Box sx={{ pt: '1vw', display: 'flex', flexDirection: 'column', gap: '1vw' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <IconButton className='side-menu-btn' data-tip={`Download Example ${creativeWithVariations ? '1' : ''}`} onClick={handleDownloadZipFile}>
                            <GrDocumentZip id="download_example-0" />
                        </IconButton>
                        {creativeWithVariations && (
                            <IconButton className='side-menu-btn' data-tip="Download Example 2" onClick={handleDownloadZipFile}>
                                <GrDocumentZip id="download_example-1" />
                            </IconButton>
                        )}
                    </Box>
                    {creativeType === UNIVERSAL && (
                        <Stack sx={{ alignItems: 'center', padding: '0 1vw' }} spacing={2}>
                            <DropDown 
                                label="Asset Type" 
                                value={type} 
                                options={ASSETS_TYPES} 
                                onChange={(value) => setType(value.name)} 
                                style={{ width: '100%' }}
                            />
                            <Stack direction={'row'} sx={{ gap: '2vw' }} width={'100%'}>
                                <NumberInput 
                                    label="Width"
                                    value={width} 
                                    onComplete={(value) => setWidth(value)} 
                                    itemId={'assetWidthInput'} 
                                />
                                <NumberInput 
                                    label="Height"
                                    value={height} 
                                    onComplete={(value) => setHeight(value)} 
                                    itemId={'assetHeightInput'} 
                                />
                                <IconButton variant="contained" onClick={addAssetHandler} sx={{ fontSize: '1vw', alignSelf: 'flex-end', marginBottom: 'calc(1.2vw - 6px)', color: '#2E3C8D' }}>
                                    <FaPlus />
                                </IconButton>
                            </Stack>
                        </Stack>
                    )}
                    <Divider />
                </Box>
                <InputBox />
                {assets?.length > 0 && <AvatarBox />}
            </div>
            <CreativeMenu {...props} />
        </div>
    )
}