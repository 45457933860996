import React from 'react'
import Carousel from 'react-elastic-carousel';

import './style.scss';

import gallery from '../../utils/images/creative_images/gallery.gif';
import half_half from '../../utils/images/creative_images/half_half.gif';
import inline from '../../utils/images/creative_images/inline.gif';
import static_interstitial from '../../utils/images/creative_images/static_interstitial.png';
import video_interstitial from '../../utils/images/creative_images/video_interstitial.gif';
import mobile_banner_video from '../../utils/images/creative_images/mobile_banner_video.gif';
import flipper from '../../utils/images/creative_images/flipper.gif';
import scratch from '../../utils/images/creative_images/scratch.gif';
import bounce_game from '../../utils/images/creative_images/bounce_game.png';
import video_timer_banner from '../../utils/images/creative_images/video_timer_banner.gif';
import video_gallery from '../../utils/images/creative_images/video_gallery.gif';
import collect_or_evade_game from '../../utils/images/creative_images/collect_or_evade_game.gif';
import program_video from '../../utils/images/creative_images/program_video.gif';
import half_video from '../../utils/images/creative_images/half_video.gif';
import desktop_timer_banner from '../../utils/images/creative_images/desktop_timer_banner.gif';
import universal from '../../utils/images/creative_images/universal.gif';
import custom_banner from '../../utils/images/creative_images/custom_banner.gif';

import desktopBorderImage from "../../utils/images/creative_images/Desktop.png";

export const CUSTOM_BANNER = 'custom_banner';
export const INTERSTITIAL = 'interstitial';
export const HALF_HALF = 'half_and_half'
export const INLINE = 'inline';
export const VIDEO_INTERSTITIAL = 'video_interstitial';
export const GALLERY = 'gallery';
export const MOBILE_BANNER_VIDEO = 'mobile_banner_video';
export const FLIPPER = 'flipper';
export const SCRATCH = 'scratch';
export const BOUNCE_GAME = 'bounce_game';
export const VIDEO_TIMER_BANNER = 'video_timer_banner';
export const VIDEO_GALLERY = 'video_gallery';
export const COLLECT_OR_EVADE_GAME = 'collect_or_evade_game';
export const UNIVERSAL = 'universal';
export const STICKY_MOBILE = 'sticky_mobile';

const CustomCarousel = (props) => {
    const breakPoints = props.breakPoints;

    const containerClassName = props.containerClassNames ? props.containerClassNames : '';
    // const btnClassName = props.btnClassNames ? props.btnClassNames : '';
    const textClassName = props.textClassNames ? props.textClassNames : '';

    const creativeImages = {
        custom_banner: custom_banner,
        interstitial: static_interstitial,
        half_and_half: half_half,
        inline: inline,
        video_interstitial: video_interstitial,
        gallery: gallery,
        mobile_banner_video: mobile_banner_video,
        flipper: flipper,
        scratch: scratch,
        bounce_game: bounce_game,
        video_timer_banner: video_timer_banner,
        desktop_timer_banner: desktop_timer_banner,
        video_gallery: video_gallery,
        collect_or_evade_game: collect_or_evade_game,
        program_video: program_video,
        half_video: half_video,
        universal: universal,
        sticky_mobile: universal
    };

    const creativeNames = {
        custom_banner: 'Custom Banner',
        interstitial: 'Static Interstitial',
        half_and_half: 'Half and Half',
        inline: 'Inline',
        video_interstitial: 'Video Interstitial',
        gallery: 'Gallery',
        mobile_banner_video: 'Mobile Banner Video',
        flipper: 'Flipper',
        scratch: 'Scratch',
        bounce_game: 'Bounce Game',
        video_timer_banner: 'Video Timer Banner',
        desktop_timer_banner: 'Desktop Timer Banner',
        video_gallery: 'Video Gallery',
        collect_or_evade_game: 'Collect or Evade Game',
        program_video: 'Program Video',
        half_video: 'Half Video',
        universal: 'Universal',
        sticky_mobile: 'Sticky Mobile'
    };

    return (
        <Carousel breakPoints={breakPoints}>
            {props.creativeTypes.map((type, index) => {
                const creatives_that_dont_have_template = ['custom_banner'];
                const horizontal_creatives = ['desktop_timer_banner', 'video_gallery'];

                let creativeTemplateType = type;
                let container_styles = {};
                let image_styles = {};

                if(creatives_that_dont_have_template.includes(type)) {
                    creativeTemplateType = 'interstitial';
                }

                if(horizontal_creatives.includes(type)) {
                    container_styles = {
                        backgroundImage: `url(${desktopBorderImage})`,
                        width: '10.208vw',
                        display: 'flex',
                        alignItems: 'flex-start',
                        paddingTop: type === 'video_gallery' ? '6%' : '5%',
                    };
                    image_styles = {
                        width: type === 'video_gallery' ? '75%' : '85%'
                    };
                }

                return (
                    <div 
                        key={index} 
                        className={`carousel-btn-container ${containerClassName}`} 
                        onClick={() => (props.functions.length ? props.functions[index] : props.functions)(type)}
                    >
                        {/* <img src={creativeImages[type]} alt={creativeNames[type]} disabled={props.uniqueUser && index > 0 ? true : false} className={`carousel-btn ${btnClassName}`} /> */}
                        <div className='carousel-btn-image-container' style={container_styles}>
                            {/*<iframe className={`carousel-btn-iframe ${isWideCreative ? "wide-creative" : ""}`} src={`https://adsil1.com/creative_examples/${creativeTemplateType}/?SA_vendor=dolphin&SA_type=app&auto_close=false`}></iframe>*/}
                            <img 
                                className='carousel-btn-image' 
                                src={creativeImages[creativeTemplateType]} 
                                style={image_styles}
                                alt=''
                            />
                        </div>
                        <p className={`carousel-text ${textClassName}`}>{creativeNames[type]}</p>
                    </div>
                );
            })}
        </Carousel>
    );
};

export default CustomCarousel;