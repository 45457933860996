import React, { useState } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { handleFieldInit } from '../CreativeTemplates/handlers';
import CreativeGrid from '../Grid/CreativeGrid';
// import NewCreative from '../PopUp/NewCreative';
import { CREATIVE } from '../../constants/path';
import { toast } from 'react-toastify';
import { deleteCreative } from '../../api_requests';
import CreativeGallery from "../CreativeGallery/CreativeGallery";
import CustomCarousel from '../UI/Carousel/CustomCarousel';
import creativeTypes, {
    // CUSTOM_BANNER,
    INTERSTITIAL,
    INLINE,
    HALF_HALF,
    VIDEO_INTERSTITIAL,
    GALLERY,
    MOBILE_BANNER_VIDEO,
    FLIPPER,
    SCRATCH,
    /*BOUNCE_GAME,*/
    VIDEO_TIMER_BANNER,
    DESKTOP_TIMER_BANNER,
    VIDEO_GALLERY,
    COLLECT_OR_EVADE_GAME,
    // PROGRAM_VIDEO,
    // HALF_VIDEO,
    UNIVERSAL,
    STICKY_MOBILE
} from '../utils/options/creativeTypes';

// import { BiMessageAdd } from "react-icons/bi";
import CreativeExamplePreview from '../PopUp/CreativeExamplePreview';
import { Typography } from '@mui/material';
import { CLEAR_CREATIVE_FIELDS, CLEAR_NEW_CREATIVE_FIELDS, SET_CREATIVE_FIELDS, SET_LEFT_DRAWER_CURRENT_TAB, SET_NEW_CREATIVE_TYPE } from '../../store/action';

export default function GridPage(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const gridPageContent = useSelector(state => state.ctrl.gridPageContent);
    const authToken = useSelector(state => state.user.token);

    const nameVal = useSelector(state => state.new_creative.name);
    const type = useSelector(state => state.new_creative.type);
    const clientID = useSelector(state => state.new_creative.clientId);
    const campaignID = useSelector(state => state.new_creative.campaignId);

    const [showCreativeExamplePreview, setShowCreativeExamplePreview] = useState(false);
    const [showCreativeState, setShowCreativeState] = useState(false);
    const [creativeTypeBtn, setCreativeTypeBtn] = useState("");
    const [carouselNewCreative, setCarouselNewCreative] = useState(false);

    const currentType = creativeTypeBtn ? creativeTypeBtn : type ? type : creativeTypes[0].id;
    const currentClient = clientID ? clientID : user.clients.length > 0 ? user.clients[0].id : "";
    const campaigns = user.campaigns.filter(c => c.clientId === currentClient);
    const currentCampaign = (campaignID) ? (campaignID) : ((campaigns.length > 0) ? (campaigns[0].id) : (null));

    const uniqueUsers = ['0575507a-78e9-4959-9bb9-2d069974c128', '62d599d1-3380-44ba-9158-bcf58795a7b0', 'c94c112e-8597-4f9f-9890-3a098bf59120', '18222015-3ae8-4336-89d0-e5ed7574215d'];
    const uniqueUser = uniqueUsers.includes(user.id);

    // const handleCreateBtnClick = (creativeType = null) => {
    //     if (user.clients.length < 1 || user.campaigns.length < 1) {
    //         toast.error('Error: Client & Campaign must be defined before creative', { theme: "colored" });
    //         return;
    //     }
    //     handleFieldInit(dispatch, {}, true);
    //     setShowCreativeState(true);

    //     if (creativeType && creativeTypes.find(type => type.id === creativeType)) {
    //         setCarouselNewCreative(true);
    //         setCreativeTypeBtn(creativeType);
    //     }
    //     else {
    //         setCarouselNewCreative(false);
    //         setCreativeTypeBtn("");
    //     }
    // };

    const handleCarouselBtnClick = (creativeType = null) => {
        if (user.clients.length < 1 || user.campaigns.length < 1) {
            toast.error('Error: Client & Campaign must be defined before creative', { theme: "colored" });
            return;
        }
        handleFieldInit(dispatch, {}, true);
        setShowCreativeExamplePreview(true);

        if (creativeType && creativeTypes.find(type => type.id === creativeType)) {
            setCarouselNewCreative(true);
            setCreativeTypeBtn(creativeType);
        }
        else {
            setCarouselNewCreative(false);
            setCreativeTypeBtn("");
        }
    };

    const handleCreativeDiscard = () => {
        setShowCreativeState(false);
        setShowCreativeExamplePreview(false);
    };

    const handleShowCreativeExamplePreview = (creativeType) => {
        dispatch({ type: CLEAR_CREATIVE_FIELDS });
        setShowCreativeExamplePreview(false);
        setShowCreativeState(true);
        dispatch({ type: SET_NEW_CREATIVE_TYPE, value: creativeType.id });
        dispatch({ type: SET_CREATIVE_FIELDS, name: nameVal, creativeType: creativeType.id, clientId: currentClient, campaignId: currentCampaign });
        // if (!nameVal && nameVal === '') {
        //     toast.error('Error: Missing creative name', { theme: "colored" });
        //     setNameError(true);
        //     return;
        // }
        // if (campaigns.length < 1) {
        //     toast.error('Error: This Client has no campaigns, choose another client or create campaign', { theme: "colored" });
        //     return;
        // }
        // if (props.creativeType)
        //     // start of creating new creative
        //     setNameError(false);
        dispatch({ type: SET_LEFT_DRAWER_CURRENT_TAB , value: 1 });
        dispatch({ type: SET_CREATIVE_FIELDS, name: nameVal, creativeType: currentType, clientId: currentClient, campaignId: currentCampaign });
        handleCreativeCreate();
        dispatch({ type: CLEAR_NEW_CREATIVE_FIELDS });
    };

    const handleCreativeCreate = () => {
        setShowCreativeState(false);
        navigate(CREATIVE);
    };

    const handleCreativeEdit = (id) => {
        dispatch({ type: SET_LEFT_DRAWER_CURRENT_TAB , value: 1 });
        navigate(CREATIVE + '/' + id);
    };

    const handleCreativeDelete = (id, callback) => {
        deleteCreative(authToken, id, (data) => {
            if (data.error !== 0) {
                toast.error('Error: ' + data.message);
                return;
            }
            callback(id);
        });
    };

    const handleEsc = (event) => {
        if (event.key === 'Escape') {
            setShowCreativeExamplePreview(false);
            setShowCreativeState(false);
        }
    }

    return (
        <div className="page-container grid-page">
            {gridPageContent === "carousel" && (
                <>
                    <div className="action-bar">
                        {/* {!uniqueUser && (
                            <div className='btns-container'>
                                <div className='new-creative-btn-container' onClick={handleCreateBtnClick}>
                                    <BiMessageAdd className="new-creative-btn" />
                                    <p className='new-creative-text'>New Creative</p>
                                </div>
                            </div>
                        )} */}
                        <Typography sx={{ width: '90%', textAlign: 'left', fontWeight: 'bold', fontSize: '1.5vw', fontFamily: 'Lexend' }}>
                            Creative Gallery
                        </Typography>
                        <div className="action-bar-carousel" style={{
                            background: 'white',
                            padding: '1vw 0',
                            borderRadius: '15px',
                            boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                            width: '90%',
                            alignSelf: 'center',
                        }}>
                            <CustomCarousel
                                uniqueUser={uniqueUser}
                                creativeTypes={[INTERSTITIAL, HALF_HALF, INLINE, UNIVERSAL, VIDEO_INTERSTITIAL, GALLERY, MOBILE_BANNER_VIDEO, FLIPPER, SCRATCH, /*BOUNCE_GAME,*/ VIDEO_TIMER_BANNER, DESKTOP_TIMER_BANNER, VIDEO_GALLERY, COLLECT_OR_EVADE_GAME, STICKY_MOBILE/*PROGRAM_VIDEO,*/ /*HALF_VIDEO,*/ /*CUSTOM_BANNER*/]}
                                btnClassNames={'colored-light'}
                                functions={handleCarouselBtnClick}
                                breakPoints={[
                                    { width: 300, itemsToShow: 1 },
                                    { width: 500, itemsToShow: 2 },
                                    { width: 650, itemsToShow: 3 },
                                    { width: 768, itemsToShow: 5 },
                                ]}
                            />
                        </div>
                    </div>
                    <CreativeGrid uniqueUser={uniqueUser} editCreative={handleCreativeEdit} deleteCreative={handleCreativeDelete} clients={user.clients} campaigns={user.campaigns} />
                </>
            )}
            {gridPageContent === "gallery" && (
                <CreativeGallery 
                    creativeTypes={[INTERSTITIAL, HALF_HALF, INLINE, UNIVERSAL, VIDEO_INTERSTITIAL, GALLERY, MOBILE_BANNER_VIDEO, FLIPPER, SCRATCH, /*BOUNCE_GAME,*/ VIDEO_TIMER_BANNER, DESKTOP_TIMER_BANNER, VIDEO_GALLERY, COLLECT_OR_EVADE_GAME, STICKY_MOBILE]}
                    functions={handleCarouselBtnClick}
                />
            )}
            {showCreativeExamplePreview && <CreativeExamplePreview discardCallback={handleCreativeDiscard} confirmCallback={handleShowCreativeExamplePreview} creativeType={creativeTypeBtn} carouselNewCreative={carouselNewCreative} showShareBtn onKeyDown={handleEsc} />}
            {/* {showCreativeState && <NewCreative popup={true} discardCallback={handleCreativeDiscard} confirmCallback={handleCreativeCreate} creativeType={creativeTypeBtn} carouselNewCreative={carouselNewCreative} onKeyDown={handleEsc} />} */}
        </div >
    )
}