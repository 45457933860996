import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    handleBannerSize, handleBannerBgImage, handleBannerBgVideo, handleBannerBgColor,
    downloadBannerAsPNG, handleShowColorPicker,
    handlePresetInit, handleShowMuteBtn, handleShowPlayBtn, handleShowBlackBtn, handlePlaceBtns,
    handleAutoClose, handleAutoCloseTimer, handleScratchComplete, handleBannerFont, handleBannerTextColor,
    handleBannerTimerEndDate, handleShowTimer, handleCompression, handleTextBackgroundColor, handleGameDuration,
    handleSpeedInterval, handleIncreaseSpeedBy, handleGameBaseSpeed, handleCollectOrEvade, handleEnableGameScore,
    handleEnableGameLives, handleBallScore, handleGridRowsNum, handleGridColumnsNum, handleVideoOnly,
    handleShowVideo, handlePlaceTimer, handleBackgroundColor, handleImageCompression, handleOpenImageSettings,
    handleOpenVideoSettings,
    handleAssetSize
} from '../CreativeTemplates/handlers';
import { enableConfirmPopUp, disableConfirmPopUp } from '../PopUp/handlers';
import { handleDeleteAllItems } from '../MoveableElements/handlers';
import { handleSideMenu } from '../StyleMenus/handlers';
import { showBannerPreview } from '../PopUp/handlers';
import { getCustomBannerHtml, getHalfHalfHtml, copyToClipboard, isValidMp4Video } from '../../utils';
import DropDown from '../Input/DropDown';
import FileButton from '../Input/FileButton';
import InputWithButton from '../Input/InputWithButton';
import TextInput from '../Input/mui_inputs/TextInput';
import NumberInput from "../Input/mui_inputs/NumberInput";
import Button from '../Input/Button';
import { BlockPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';

import sizes, { DESKTOP_TIMER_BANNER_SIZES, PROGRAM_VIDE_GRID_SIZES } from '../utils/options/sizes';
import { DEFAULT_PRESET_ID } from '../utils/options/adPresets';
import creativeTypes, { CUSTOM_BANNER, DESKTOP_TIMER_BANNER, INLINE } from '../utils/options/creativeTypes';

import { AiOutlineVideoCameraAdd, AiOutlineFileImage } from "react-icons/ai";
import { RiImageAddFill } from "react-icons/ri";
import { IoMdColorFill } from "react-icons/io";
import { BiCodeBlock } from "react-icons/bi";
import { IoCodeSlashOutline } from "react-icons/io5";

import { HALF_HALF } from '../utils/options/creativeTypes';

import PresetModal from './PresetModal';
import useModal from '../../hooks/usePresetModal';

import { Box, Slider, Stack, Typography } from "@mui/material";
import FontAutoComplete from "../AutoComplete/FontAutoComplete";
import DateTimeInput from "../Input/mui_inputs/DateTimeInput";
import RadioGroupInput from "../Input/mui_inputs/RadioGroupInput";

export default function CreativeMenu(props) {
    const mp4Link = "Add mp4 link";
    const { toggle, visible } = useModal();
    const dispatch = useDispatch();
    const creative = useSelector(state => state.creative);
    const bannerWidth = useSelector(state => state.creative.bannerWidth);
    const bannerHeight = useSelector(state => state.creative.bannerHeight);
    const bgColor = useSelector(state => state.creative.bgColor);
    const showColorPicker = useSelector(state => state.creative.showColorPicker);
    const mute = useSelector(state => state.creative.mute);
    const play = useSelector(state => state.creative.play);
    const darkMode = useSelector(state => state.creative.darkMode);
    const buttonsPosition = useSelector(state => state.creative.buttonsPosition);
    const textColor = useSelector(state => state.creative.textColor);
    const timerEndDate = useSelector(state => state.creative.timerEndDate);
    const autoClose = useSelector(state => state.creative.autoClose);
    const autoCloseTimer = useSelector(state => state.creative.autoCloseTimer);
    const showTimer = useSelector(state => state.creative.showTimer);

    // video compression
    const compression = useSelector(state => state.creative.compression);
    const imageCompression = useSelector(state => state.localState.imageCompression);
    const openVideoSettings = useSelector(state => state.localState.openVideoSettings);
    const openImageSettings = useSelector(state => state.localState.openImageSettings);
    const scratchComplete = useSelector(state => state.creative.scratchComplete);
    const textBackgroundColor = useSelector(state => state.creative.textBackgroundColor);
    const backgroundColor = useSelector(state => state.creative.backgroundColor);
    const gameDuration = useSelector(state => state.creative.gameDuration);
    const speedInterval = useSelector(state => state.creative.speedInterval);
    const increaseSpeedBy = useSelector(state => state.creative.increaseSpeedBy);
    const swiperSpeed = useSelector(state => state.creative.swiperSpeed);
    const gameBaseSpeed = useSelector(state => state.creative.gameBaseSpeed);
    const collectOrEvade = useSelector(state => state.creative.collectOrEvade);
    const enableGameScore = useSelector(state => state.creative.enableGameScore);
    const enableGameLives = useSelector(state => state.creative.enableGameLives);
    const gridRowsNum = useSelector(state => state.creative.programVideo.gridRowsNum);
    const gridColumnsNum = useSelector(state => state.creative.programVideo.gridColumnsNum);
    const videoOnly = useSelector(state => state.creative.videoOnly);
    const timerPosition = useSelector(state => state.creative.timerPosition);
    const showVideo = useSelector(state => state.creative.showVideo);
    const currentSizes = sizes.find(size => size.sizes.find((s) => s.id === `${bannerWidth}x${bannerHeight}`));


    let currentSize;
    if (creative.type === CUSTOM_BANNER) {
        if (currentSizes) {
            currentSize = currentSizes.sizes.find(size => size.id === `${bannerWidth}x${bannerHeight}`);
        }
    } else {
        currentSize = DESKTOP_TIMER_BANNER_SIZES.find(size => size.id === `${bannerWidth}x${bannerHeight}`);
    }

    const creativeSizes = creative.type === CUSTOM_BANNER ? sizes : creative.type === DESKTOP_TIMER_BANNER ? DESKTOP_TIMER_BANNER_SIZES : [];
    const creativeSizesWithLabels = creative.type === CUSTOM_BANNER ? true : false;

    const videoInputState = "";
    const bgImageFileInput = "bgImageFileInput";
    const creativeOptions = creativeTypes.find(type => type.id === creative.type);

    const [presetSettings, setPresetSettings] = useState(null);
    const creativeMenuRef = useRef(null);

    let selectedSize = null;

    const handleImageCompressionChange = (event) => {
        handleImageCompression(dispatch, event.target.value)
    }

    const handleSizeOptionChange = (selected) => {
        selectedSize = selected;


        if (props.itemsCount > 0) {
            const content = {
                // title: 'Confirm changes',
                text: 'Following changes will remove all elements from banner, are you sure you want to proceed?'//, okBtnText:'Ok', noBtnText:'Cancel'
            };
            enableConfirmPopUp(dispatch, handleSizeChangeConfirm, handleConfirmPopUpDisable, content);
        }
        else {
            handleBannerSize(dispatch, selectedSize.width, selectedSize.height);
            disableConfirmPopUp(dispatch);
            if(creative.type === DESKTOP_TIMER_BANNER) {
                // Background Image - order = 0
                handleAssetSize(dispatch, selectedSize.width, selectedSize.height, 0);
            }
        }
    };

    const handleSizeChangeConfirm = () => {
        handleBannerSize(dispatch, selectedSize.width, selectedSize.height);
        handleDeleteAllItems(dispatch);
        handleSideMenu(dispatch, 'delete');
        disableConfirmPopUp(dispatch);
    };

    const handleConfirmPopUpDisable = () => {
        disableConfirmPopUp(dispatch);
    };

    const handleFontChange = (value) => {
        handleBannerFont(dispatch, value);
    };

    const handleTextColorChange = (value) => {
        handleBannerTextColor(dispatch, value);
    };

    const handleShowTimerChange = (event) => {
        const value = event.target.value === 'true';
        handleShowTimer(dispatch, value);
    };
    
    const handleShowVideoChange = (event) => {
        const value = event.target.value === 'true';
        handleShowVideo(dispatch, value);
        if(value === false) {
            if(play) handleShowPlayBtn(dispatch, false);
            if(mute) handleShowMuteBtn(dispatch, false);
            if(videoOnly) handleVideoOnly(dispatch, false);
        }
    };

    const handleVideoCompressionChange = (event) => {
        handleCompression(dispatch, event.target.value);
    }

    const handleOpenImageSettingsChange = () => {
        handleOpenImageSettings(dispatch, !openImageSettings);
    }

    const handleOpenVideoSettingsChange = () => {
        handleOpenVideoSettings(dispatch, !openVideoSettings);
    }

    const handleTimerEndDateChange = (value) => {
        const string_value = (value['$d']);

        const month = string_value.getMonth() + 1;
        const day = string_value.getDate();
        const hours = string_value.getHours();
        const minutes = string_value.getMinutes();
        const seconds = string_value.getSeconds();

        const date = {
            year: string_value.getFullYear(),
            month: month < 10 ? '0' + month : month,
            day: day < 10 ? '0' + day : day,
            hours: hours < 10 ? '0' + hours : hours,
            minutes: minutes < 10 ? '0' + minutes : minutes,
            seconds: seconds < 10 ? '0' + seconds : seconds,
        };
        const sql_formatted_value = `${date.year}-${date.month}-${date.day} ${date.hours}:${date.minutes}:${date.seconds}`;
        // console.log(sql_formatted_value);
        handleBannerTimerEndDate(dispatch, sql_formatted_value);
    };

    const openHtmlPreview = () => {
        showBannerPreview(dispatch);
    };

    const handleHtmlByCreativeType = (type) => {
        let previewData;
        switch (type) {
            case HALF_HALF:
                previewData = getHalfHalfHtml(creative);
                break;
            default:
                previewData = getCustomBannerHtml(creative, props.images, props.texts, props.shapes);
        }

        return previewData;
    }

    const copyTag = () => {
        const generatedPreview = handleHtmlByCreativeType(creative.type);//getCustomBannerHtml(banner, props.images, props.texts, props.shapes);
        copyToClipboard(generatedPreview.html);
    };

    const handleBackgroundColorPickerClick = () => {
        handleShowColorPicker(dispatch, !showColorPicker);
    };

    const handleVideoIconClick = (event, value) => {
        if (creative.backgroundColor === '#ffffff' && !creative.backgroundImage) {
            handleBannerBgVideo(dispatch, event);
            return;
        }
        handleBackgroundChangeConfirm(() => {
            handleBannerBgVideo(dispatch, value);
            disableConfirmPopUp(dispatch);
        });
    };

    const handleColorPickerChangeComplete = (color) => {
        if (!creative.backgroundImage && !creative.backgroundVideo) {
            handleBannerBgColor(dispatch, color);
            return;
        }
        handleBackgroundChangeConfirm(() => {
            handleBannerBgColor(dispatch, color);
            disableConfirmPopUp(dispatch);
        });
    };

    const handleBackgroundImageChange = (event) => {
        if (creative.backgroundColor === '#ffffff' && !creative.backgroundVideo) {
            handleBannerBgImage(dispatch, event);
            return;
        }

        handleBackgroundChangeConfirm(() => {
            handleBannerBgImage(dispatch, event);
            disableConfirmPopUp(dispatch);
        });
    };

    const handleBackgroundChangeConfirm = (callback) => {
        const content = {
            text: 'You can have only one of [image/video/color] set at a time, are you sure you want to proceed?'
        };
        enableConfirmPopUp(dispatch, callback, handleConfirmPopUpDisable, content);
    };

    const handlePresetOptionChange = (option) => {
        if (option.id === DEFAULT_PRESET_ID) return;
        setPresetSettings(option);
        toggle();
    };

    const handlePresetSaveCallback = (data) => {
        handlePresetInit(dispatch, data);
        toggle();
    };

    const handlePlayBtnChange = (event) => {
        const value = event.target.value === 'true';
        handleShowPlayBtn(dispatch, value);
    };
    
    const handleVolumeBtnChange = (event) => {
        const value = event.target.value === 'true';
        handleShowMuteBtn(dispatch, value);
    };
    
    const handleVideoOnlyChange = (event) => {
        const value = event.target.value === 'true';
        handleVideoOnly(dispatch, value);
    };

    // const handleAlignLeftTimerClick = () => {
    //     handlePlaceTimer(dispatch, 'Left');
    // }
    // const handleAlignCenterTimerClick = () => {
    //     handlePlaceTimer(dispatch, 'Center');
    // }
    // const handleAlignRightTimerClick = () => {
    //     handlePlaceTimer(dispatch, 'Right');
    // }
    const handleTimerPositionChange = (event) => {
        handlePlaceTimer(dispatch, event.target.value);
    }

    const handleBlackBtnChange = (event) => {
        const value = event.target.value === 'true';
        handleShowBlackBtn(dispatch, value);
    };

    const handleBtnsPositionChange = (event) => {
        handlePlaceBtns(dispatch, event.target.value);
    }

    // const handleAlignTopBtnsClick = () => {
    //     handlePlaceBtns(dispatch, 'Top');
    // }
    // const handleAlignCenterBtnsClick = () => {
    //     handlePlaceBtns(dispatch, 'Center');
    // }
    // const handleAlignBottomBtnsClick = () => {
    //     handlePlaceBtns(dispatch, 'Bottom');
    // }

    const handleAutoCloseClick = (event) => {
        const value = event.target.value === 'true';
        handleAutoClose(dispatch, value);
    };

    const handleAutoCloseTimerChange = (value) => {
        handleAutoCloseTimer(dispatch, value);
    };

    const handleScratchCompleteChange = (value) => {
        handleScratchComplete(dispatch, value);
    };

    const handleTextBackgroundColorChange = (value) => {
        handleTextBackgroundColor(dispatch, value);
    };

    const handleBackgroundColorChange = (value) => {
        handleBackgroundColor(dispatch, value);
    };

    const handleGameDurationCahnge = (value) => {
        handleGameDuration(dispatch, value);
    };

    const handleSpeedIntervalChange = (value) => {
        handleSpeedInterval(dispatch, value);
    };

    const handleIncreaseSpeedByChange = (value) => {
        handleIncreaseSpeedBy(dispatch, value);
    };

    const handleGameBaseSpeedChange = (value) => {
        handleGameBaseSpeed(dispatch, value);
    };

    const handleCollectOrEvadeChange = (event) => {
        const value = event.target.value === 'true';
        handleCollectOrEvade(dispatch, value);
    };

    const handleEnableGameScoreChange = (event) => {
        const value = event.target.value === 'true';
        handleEnableGameScore(dispatch, value);
    };

    const handleEnableGameLivesChange = (event) => {
        const value = event.target.value === 'true';
        handleEnableGameLives(dispatch, value);
    };

    const handleGridSizeChange = (value) => {
        handleGridRowsNum(dispatch, value.gridRowsNum);
        handleGridColumnsNum(dispatch, value.gridColumnsNum);
    };

    const handleBallScoreChange = (value, event) => {
        const itemId = event.target.id.split(" ")[1];
        const currentAsset = creative.assets.find(asset => asset.id === itemId || asset.uiId === itemId);
        if (currentAsset) {
            handleBallScore(dispatch, itemId, { ...currentAsset, score: value });
        }
    };

    // set swiper speed in ms in rudex store
    const handleSwiperSpeedChange = (value) => {
        if(value < 0 || value > 10000) return;
        dispatch({ type: "SET_SWIPER_SPEED", value });
    }

    // add more assets to sticky_mobile creative
    const handleAddNewStickyMobileImage = () => {
        if(creative.assets.length >= 10) return;
        dispatch({ type: "ADD_MOBILE_STICKY_ASSET", value: {} });
    }

    // const formateCreativeType = useMemo(() => {
    //     if (!creative.type) {
    //         return "";
    //     }
    //     const words = creative.type.split("_");
    //     let result = '';
    //     if (words.length === 1) {
    //         // if creative type is only 1 words
    //         result = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    //     } else {
    //         // if creative type is 2 words +
    //         const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
    //         result = capitalizedWords.join(" ");
    //     }
    //     return result;
    // }, [creative.type]);

    useEffect(() => {
        if (creative.type === INLINE) {
            handleAutoClose(dispatch, false);
        }
    }, [dispatch, creative.type]);

    return (
        <div className="banner-menu" ref={creativeMenuRef}>
            <div className="banner-menu-inner">
                <PresetModal visible={visible} toggle={toggle} settings={presetSettings} callback={handlePresetSaveCallback} />
                <ReactTooltip />
                <div className="ds-layout">
                    <Stack spacing={2} width={'100%'} sx={{ padding: { lg: '0 2vw' } }}>
                        {creativeOptions?.options?.sizeSelect && <DropDown sx={{ width: '10vw' }} label="Canvas Size" value={currentSize?.id} onChange={handleSizeOptionChange} options={creativeSizes} withLabels={creativeSizesWithLabels} />}
                        {creativeOptions?.options?.fontOptions && (
                            <FontAutoComplete label="Font" onChange={handleFontChange} />
                        )}
                        {creativeOptions?.options?.textControls && (
                            <Stack spacing={2}>
                                <TextInput label="Text Color" value={textColor} onComplete={handleTextColorChange} itemId={'bannerTextColorInput'} />
                                <TextInput label="Background Color" value={backgroundColor} onComplete={handleBackgroundColorChange} itemId={'bannerBackgroundColorInput'} />
                            </Stack>
                        )}
                    </Stack>

                </div>
                <div className="ds-layout">
                    <Stack spacing={2} width={'100%'} sx={{ padding: { lg: '0 2vw' } }}>
                        {creativeOptions?.options?.swiperSpeed && (
                            <Stack direction={"column"} spacing={2} sx={{ gap: {xl: '1vw'} }}>
                                <Box>
                                    <Button 
                                        variant="contained" 
                                        onClick={handleAddNewStickyMobileImage}
                                    >Add More</Button>
                                </Box>
                                <NumberInput 
                                    label="Swiper speed" 
                                    minValue={100} 
                                    maxValue={10000} 
                                    value={creative.swiperSpeed} 
                                    onComplete={handleSwiperSpeedChange} 
                                    itemId={'swiperSpeed'} 
                                />
                            </Stack>
                        )}
                        {creativeOptions?.options?.timerControls && (
                            <>
                                {/* showTimer */}
                                <RadioGroupInput
                                    onClick={handleShowTimerChange}
                                    groupSx={{
                                        alignItems: 'flex-start',
                                        flexDirection: 'row'
                                    }}
                                    label="Timer"
                                    labelSx={{ 
                                        color: 'black',
                                        fontSize: '1.111vw',
                                        fontWeight: 'bold', 
                                        fontFamily: 'Lexend',
                                        lineHeight: '2vw',
                                    }}
                                    radios={[
                                        {
                                            label: "On",
                                            value: true,
                                            sx: {
                                                marginLeft: 0,
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '0.85vw',
                                                }
                                            },
                                        },
                                        {
                                            label: "Off",
                                            value: false,
                                            sx: {
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '0.85vw',
                                                }
                                            },
                                        },
                                    ]}
                                    value={showTimer}
                                />
                                    {showTimer && (
                                        <DateTimeInput timer={timerEndDate} onChange={handleTimerEndDateChange} label="End Date" minValue={new Date()} />
                                    )}
                                {showTimer && (
                                    // timerPosition
                                    <RadioGroupInput
                                        onClick={handleTimerPositionChange}
                                        groupSx={{
                                            alignItems: 'flex-start',
                                            flexDirection: 'row'
                                        }}
                                        label="Timer Position"
                                        labelSx={{ 
                                            color: 'black',
                                            fontSize: '1.111vw',
                                            fontWeight: 'bold', 
                                            fontFamily: 'Lexend',
                                            lineHeight: '2vw',
                                        }}
                                        radios={[
                                            {
                                                label: "Left",
                                                value: "Left",
                                                sx: {
                                                    marginLeft: 0,
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                            {
                                                label: "Center",
                                                value: "Center",
                                                sx: {
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                            {
                                                label: "Right",
                                                value: "Right",
                                                sx: {
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                        ]}
                                        value={timerPosition}
                                    />
                                )}
                            </>
                        )}
                    </Stack>

                </div>
                <div className="ds-layout">
                    <Stack spacing={2} width={'100%'} sx={{ padding: { lg: '0 2vw' } }}>
                        {creativeOptions?.options?.bgVideo &&
                            <InputWithButton className="rounded-40" placeholder={mp4Link} value={videoInputState} onClick={handleVideoIconClick} icon={<AiOutlineVideoCameraAdd data-tip="Add video to background" />} validation={isValidMp4Video} />
                        }
                        {creativeOptions?.options?.bgColor &&
                            <div className="ds-inputs ds-i-button rounded-40">
                                <IoMdColorFill className="color-icon" onClick={handleBackgroundColorPickerClick} data-tip="Choose background color" />
                            </div>
                        }
                        {creativeOptions?.options?.bgImage &&
                            <FileButton className="rounded-40" elemId={bgImageFileInput} onInput={handleBackgroundImageChange} acceptedFormat="image/*" icon={<RiImageAddFill data-tip="Upload background image" />} />
                        }
                        {creativeOptions?.options?.videoControls && (
                            <Stack spacing={2}>
                                <Stack 
                                    direction="row" 
                                    sx={{
                                        gap: {
                                            lg: '1.35vw',
                                            xl: '1.5vw'
                                        }
                                    }}
                                >
                                    {/* play */}
                                    <RadioGroupInput
                                        onClick={handlePlayBtnChange}
                                        groupSx={{
                                            alignItems: 'flex-start',
                                            flexDirection: 'row'
                                        }}
                                        label="Play Button"
                                        labelSx={{ 
                                            color: 'black',
                                            fontSize: '1.111vw',
                                            fontWeight: 'bold', 
                                            fontFamily: 'Lexend',
                                            lineHeight: '2vw',
                                        }}
                                        radios={[
                                            {
                                                label: "On",
                                                value: true,
                                                sx: {
                                                    marginLeft: 0,
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                            {
                                                label: "Off",
                                                value: false,
                                                sx: {
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                        ]}
                                        value={play}
                                    />
                                    {(play || mute) && (
                                        // darkMode
                                        <RadioGroupInput
                                            onClick={handleBlackBtnChange}
                                            groupSx={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row'
                                            }}
                                            label="Buttons Color"
                                            labelSx={{ 
                                                color: 'black',
                                                fontSize: '1.111vw',
                                                fontWeight: 'bold', 
                                                fontFamily: 'Lexend',
                                                lineHeight: '2vw',
                                            }}
                                            radios={[
                                                {
                                                    label: "White",
                                                    value: false,
                                                    sx: {
                                                        marginLeft: 0,
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Black",
                                                    value: true,
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                            ]}
                                            value={darkMode}
                                        />
                                    )}
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    sx={{
                                        gap: {
                                            lg: '1.35vw',
                                            xl: '1.5vw'
                                        }
                                    }}
                                >
                                    {/* mute */}
                                    <RadioGroupInput
                                        onClick={handleVolumeBtnChange}
                                        groupSx={{
                                            alignItems: 'flex-start',
                                            flexDirection: 'row'
                                        }}
                                        label="Mute Button"
                                        labelSx={{ 
                                            color: 'black',
                                            fontSize: '1.111vw',
                                            fontWeight: 'bold', 
                                            fontFamily: 'Lexend',
                                            lineHeight: '2vw',
                                        }}
                                        radios={[
                                            {
                                                label: "On",
                                                value: true,
                                                sx: {
                                                    marginLeft: 0,
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                            {
                                                label: "Off",
                                                value: false,
                                                sx: {
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.85vw',
                                                    }
                                                },
                                            },
                                        ]}
                                        value={mute}
                                    />
                                    {(play || mute) && (
                                        // buttonsPosition
                                        <RadioGroupInput
                                            onClick={handleBtnsPositionChange}
                                            groupSx={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row'
                                            }}
                                            label="Buttons Position"
                                            labelSx={{ 
                                                color: 'black',
                                                fontSize: '1.111vw',
                                                fontWeight: 'bold', 
                                                fontFamily: 'Lexend',
                                                lineHeight: '2vw',
                                            }}
                                            radios={[
                                                {
                                                    label: "Top",
                                                    value: "Top",
                                                    sx: {
                                                        marginLeft: 0,
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Center",
                                                    value: "Center",
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Bottom",
                                                    value: "Bottom",
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                            ]}
                                            value={buttonsPosition}
                                        />
                                    )}
                                </Stack>
                                {(creative.type === DESKTOP_TIMER_BANNER || creative.type === INLINE) && (
                                    <Stack 
                                        direction="row"
                                        sx={{
                                            gap: {
                                                lg: '1.35vw',
                                                xl: '1.5vw'
                                            }
                                        }}
                                    >
                                        {/* videoOnly */}
                                        <RadioGroupInput
                                            onClick={handleVideoOnlyChange}
                                            groupSx={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row'
                                            }}
                                            label="Video Only"
                                            labelSx={{ 
                                                color: 'black',
                                                fontSize: '1.111vw',
                                                fontWeight: 'bold', 
                                                fontFamily: 'Lexend',
                                                lineHeight: '2vw',
                                            }}
                                            radios={[
                                                {
                                                    label: "On",
                                                    value: true,
                                                    sx: {
                                                        marginLeft: 0,
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Off",
                                                    value: false,
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                            ]}
                                            value={videoOnly}
                                        />
                                        {/* showVideo */}
                                        <RadioGroupInput
                                            onClick={handleShowVideoChange}
                                            groupSx={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row'
                                            }}
                                            label="Show Video"
                                            labelSx={{ 
                                                color: 'black',
                                                fontSize: '1.111vw',
                                                fontWeight: 'bold', 
                                                fontFamily: 'Lexend',
                                                lineHeight: '2vw',
                                            }}
                                            radios={[
                                                {
                                                    label: "On",
                                                    value: true,
                                                    sx: {
                                                        marginLeft: 0,
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Off",
                                                    value: false,
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                            ]}
                                            value={showVideo}
                                        />
                                    </Stack>
                                )}

                                {creativeOptions?.options?.videoControls && (
                                    <Stack 
                                        direction="row"
                                        sx={{
                                            gap: {
                                                lg: '1.35vw',
                                                xl: '1.5vw'
                                            }
                                        }}
                                    >
                                        {/* openVideoSettings */}
                                        <RadioGroupInput
                                            onClick={handleOpenVideoSettingsChange}
                                            groupSx={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row'
                                            }}
                                            label="Video Compression"
                                            labelSx={{ 
                                                color: 'black',
                                                fontSize: '1.111vw',
                                                fontWeight: 'bold', 
                                                fontFamily: 'Lexend',
                                                lineHeight: '2vw',
                                            }}
                                            radios={[
                                                {
                                                    label: "On",
                                                    value: true,
                                                    sx: {
                                                        marginLeft: 0,
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                                {
                                                    label: "Off",
                                                    value: false,
                                                    sx: {
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.85vw',
                                                        }
                                                    },
                                                },
                                            ]}
                                            value={openVideoSettings}
                                        />
                                        {openVideoSettings && (
                                            <Box>
                                                <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                                                    <Typography
                                                        sx={{ 
                                                            color: 'black',
                                                            fontSize: '1.111vw',
                                                            fontWeight: 'bold', 
                                                            fontFamily: 'Lexend',
                                                            lineHeight: '2vw',
                                                        }}
                                                    >
                                                        Compression Level
                                                    </Typography>
                                                    <Slider
                                                        sx={{ width: '100%', color: '#83d1ff', padding: '1.5vw 0' }}
                                                        defaultValue={28}
                                                        value={compression}
                                                        valueLabelDisplay="auto"
                                                        max={50}
                                                        min={0}
                                                        onChange={handleVideoCompressionChange}
                                                    />
                                                </Stack>
                                            </Box>
                                        )}
                                    </Stack>
                                )}
                            </Stack>
                        )}
                        
                        <Stack spacing={2} width={'100%'}>
                            <Stack 
                                direction="row"
                                sx={{
                                    gap: {
                                        lg: '1.35vw',
                                        xl: '1.5vw'
                                    }
                                }}
                            >
                                {/* openImageSettings */}
                                <RadioGroupInput
                                    onClick={handleOpenImageSettingsChange}
                                    groupSx={{
                                        alignItems: 'flex-start',
                                        flexDirection: 'row'
                                    }}
                                    label="Image Compression"
                                    labelSx={{ 
                                        color: 'black',
                                        fontSize: '1.111vw',
                                        fontWeight: 'bold', 
                                        fontFamily: 'Lexend',
                                        lineHeight: '2vw',
                                    }}
                                    radios={[
                                        {
                                            label: "On",
                                            value: true,
                                            sx: {
                                                marginLeft: 0,
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '0.85vw',
                                                }
                                            },
                                        },
                                        {
                                            label: "Off",
                                            value: false,
                                            sx: {
                                                '& .MuiFormControlLabel-label': {
                                                    fontSize: '0.85vw',
                                                }
                                            },
                                        },
                                    ]}
                                    value={openImageSettings}
                                />
                                {openImageSettings && (
                                    <Box>
                                        <Stack sx={{ justifyContent: 'space-between', alignItems: 'center', height: '100%' }}>
                                            <Typography
                                                sx={{ 
                                                    color: 'black',
                                                    fontSize: '1.111vw',
                                                    fontWeight: 'bold', 
                                                    fontFamily: 'Lexend',
                                                    lineHeight: '2vw',
                                                }}
                                            >
                                                Compression Level
                                            </Typography>
                                            <Slider
                                                sx={{ width: '100%', color: '#83d1ff', padding: '1.5vw 0' }}
                                                defaultValue={0.5}
                                                value={imageCompression}
                                                valueLabelDisplay="auto"
                                                max={1}
                                                min={0}
                                                step={0.01}
                                                onChange={handleImageCompressionChange}
                                                valueLabelFormat={(value) => `${Math.round(value * 100)}%`}
                                            />
                                        </Stack>
                                    </Box>
                                )}
                            </Stack>

                            {/* autoClose */}
                            <RadioGroupInput
                                onClick={handleAutoCloseClick}
                                groupSx={{
                                    alignItems: 'flex-start',
                                    flexDirection: 'row'
                                }}
                                label="Auto Close"
                                labelSx={{ 
                                    color: 'black',
                                    fontSize: '1.111vw',
                                    fontWeight: 'bold', 
                                    fontFamily: 'Lexend',
                                    lineHeight: '2vw',
                                }}
                                radios={[
                                    {
                                        label: "On",
                                        value: true,
                                        sx: {
                                            marginLeft: 0,
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                    {
                                        label: "Off",
                                        value: false,
                                        sx: {
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                ]}
                                value={autoClose}
                            />
                            <NumberInput 
                                label="Duration (Miliseconds)"
                                value={autoCloseTimer} 
                                onComplete={handleAutoCloseTimerChange} 
                                itemId={'autoCloseTimerInput'} 
                            />
                        </Stack>
                        {
                            creative.type === 'scratch' && (
                                <NumberInput 
                                    label="Scratch Completed at (%)" 
                                    minValue={0} 
                                    maxValue={100} 
                                    value={scratchComplete} 
                                    onComplete={handleScratchCompleteChange} 
                                    itemId={'scratchCompleteInput'} 
                                />
                            )
                        }
                    </Stack>
                    {creativeOptions?.options?.tagOptions ?
                        <div className="button-group">
                            <IoCodeSlashOutline className="item rounded-40" onClick={copyTag} data-tip="Copy Tag to Clipboard" />
                            <AiOutlineFileImage className="item rounded-40" onClick={downloadBannerAsPNG} data-tip="Download as image" />
                            <BiCodeBlock className="item rounded-40" onClick={openHtmlPreview} data-tip="Creative Preview" />
                        </div>
                        : null}

                    {creativeOptions && creativeOptions.options.sizeSelect && currentSize?.hasPresets ?
                        <div className="button-group left-flex">
                            <DropDown style={{ width: '8vw' }} label="Template" value={currentSize.presetOptions.id} options={currentSize.presetOptions} onChange={handlePresetOptionChange} />
                        </div>
                        : null}
                </div>
                <div className="ds-layout">
                    {creativeOptions?.options?.gameControls && (
                        <Stack spacing={2} width="100%" sx={{ padding: { lg: '0 2vw' } }}>
                            <TextInput 
                                label="Text Background Color" 
                                value={textBackgroundColor} 
                                onComplete={handleTextBackgroundColorChange} 
                                itemId={'bannerTextBackgroundColorInput'} 
                            />
                            <NumberInput 
                                label="Game Duration" 
                                value={gameDuration} 
                                onComplete={handleGameDurationCahnge} 
                                itemId={'gameDurationInput'} 
                            />
                            <NumberInput 
                                label="Speed Intervals" 
                                value={speedInterval} 
                                onComplete={handleSpeedIntervalChange} 
                                itemId={'speedIntervalInput'} 
                            />
                            <NumberInput 
                                label="Increase Speed By (0 - 1)"
                                value={increaseSpeedBy} 
                                onComplete={handleIncreaseSpeedByChange} 
                                itemId={'increaseSpeedByInput'} 
                            />
                            <NumberInput 
                                label="Base Speed" 
                                value={gameBaseSpeed} 
                                onComplete={handleGameBaseSpeedChange} 
                                itemId={'gameBaseSpeedInput'} 
                            />
                            {/* collectOrEvade */}
                            <RadioGroupInput
                                onClick={handleCollectOrEvadeChange}
                                groupSx={{
                                    alignItems: 'flex-start',
                                    flexDirection: 'row'
                                }}
                                label="Game Mode"
                                labelSx={{ 
                                    color: 'black',
                                    fontSize: '1.111vw',
                                    fontWeight: 'bold', 
                                    fontFamily: 'Lexend',
                                    lineHeight: '2vw',
                                }}
                                radios={[
                                    {
                                        label: "Collect",
                                        value: true,
                                        sx: {
                                            marginLeft: 0,
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                    {
                                        label: "Evade",
                                        value: false,
                                        sx: {
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                ]}
                                value={collectOrEvade}
                            />
                            {/* enableGameScore */}
                            <RadioGroupInput
                                onClick={handleEnableGameScoreChange}
                                groupSx={{
                                    alignItems: 'flex-start',
                                    flexDirection: 'row'
                                }}
                                label="Game Score"
                                labelSx={{ 
                                    color: 'black',
                                    fontSize: '1.111vw',
                                    fontWeight: 'bold', 
                                    fontFamily: 'Lexend',
                                    lineHeight: '2vw',
                                }}
                                radios={[
                                    {
                                        label: "Enable",
                                        value: true,
                                        sx: {
                                            marginLeft: 0,
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                    {
                                        label: "Disable",
                                        value: false,
                                        sx: {
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                ]}
                                value={enableGameScore}
                            />
                            {/* enableGameLives */}
                            <RadioGroupInput
                                onClick={handleEnableGameLivesChange}
                                groupSx={{
                                    alignItems: 'flex-start',
                                    flexDirection: 'row'
                                }}
                                label="Game Lives"
                                labelSx={{ 
                                    color: 'black',
                                    fontSize: '1.111vw',
                                    fontWeight: 'bold', 
                                    fontFamily: 'Lexend',
                                    lineHeight: '2vw',
                                }}
                                radios={[
                                    {
                                        label: "Enable",
                                        value: true,
                                        sx: {
                                            marginLeft: 0,
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                    {
                                        label: "Disable",
                                        value: false,
                                        sx: {
                                            '& .MuiFormControlLabel-label': {
                                                fontSize: '0.85vw',
                                            }
                                        },
                                    },
                                ]}
                                value={enableGameLives}
                            />
                        </Stack>
                    )}
                </div>
                {creativeOptions?.options?.gameControls && (
                    <div className="ds-layout">
                        <Stack
                            direction="column"
                            spacing={2}
                            width="100%"
                            sx={{ padding: { lg: '0 2vw' } }}
                        >
                            <Typography 
                                variant="h6" 
                                sx={{ 
                                    color: 'black',
                                    fontSize: '1.111vw',
                                    fontWeight: 'bold', 
                                    fontFamily: 'Lexend',
                                    lineHeight: '2vw',
                                }}
                            >
                                Ball Score
                            </Typography>
                            <Stack
                                direction="row"
                                sx={{
                                    justifyContent: 'space-around'
                                }}
                            >
                                {creative.assets.map((asset) => {
                                    // console.log(creative.assets);
                                    if (asset.type === 'Ball_Image' || asset.type === 'ball_image') {
                                        return (
                                            <Stack
                                                gap={2}
                                                key={asset.type + asset.order}
                                                sx={{
                                                    alignItems: 'center',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <Typography>
                                                    {asset.order - 5}
                                                </Typography>
                                                <NumberInput 
                                                    id={`ball ${asset.id || asset.uiId}`} 
                                                    value={asset.score} 
                                                    onComplete={handleBallScoreChange} 
                                                    itemId={`GameScore${asset.order}`} 
                                                    canBeNegative={true} 
                                                    sx={{
                                                        alignItems: 'center',
                                                        justifyContent: 'space-evenly',
                                                        width: '3.5vw'
                                                    }}
                                                    inputTextCenter={true}
                                                    circledInput={true}
                                                    minValue={0}
                                                    maxValue={99}
                                                />
                                            </Stack>
                                        );
                                    }
                                    return null;
                                })}
                            </Stack>
                        </Stack>
                    </div>
                )}
                {creativeOptions?.options?.programVideo && (
                    <Stack 
                        direction="column"
                        spacing={2}
                        width="100%"
                        sx={{ padding: { lg: '0 2vw' } }}
                    >
                        <DropDown 
                            label="Rows X Columns" 
                            value={`${gridRowsNum}x${gridColumnsNum}`} 
                            options={PROGRAM_VIDE_GRID_SIZES} 
                            onChange={handleGridSizeChange} 
                        />
                    </Stack>
                )}

            </div>
            {showColorPicker ? <BlockPicker color={bgColor} onChangeComplete={handleColorPickerChangeComplete} /> : null}
        </div >
    )
}
